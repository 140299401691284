type PageProps = {
  children: any;
  removeWrapper?: boolean;
};

const TriumphPage = (props: PageProps) => {
  if (props.removeWrapper) return <>{props.children}</>;
  return <div className="page-wrapper">{props.children}</div>;
};

export default TriumphPage;
