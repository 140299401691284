import { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const OverlayLoader: FC = () => {
  return (
    <div className="overlay-whole-screen">
      <div className="loader-overlay">
        <LoadingOutlined size={64} />
      </div>
    </div>
  );
};

export default OverlayLoader;
