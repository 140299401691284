import { Descriptions, Select } from "antd";
import Title from "antd/lib/typography/Title";
import {
  collection,
  doc,
  FirestoreDataConverter,
  onSnapshot,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { db, generateFirestorePath } from "src/helpers";
import { dateFormatter } from "src/shared/config/constants";
import { useMediaQuery } from "react-responsive";
import GameName from "src/shared/components/GameName/gameName";

const fireStoreConverter: FirestoreDataConverter<UserGameState> = {
  toFirestore(data) {
    return data;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot<UserGameState>,
    options: SnapshotOptions
  ): UserGameState {
    const data = snapshot.data(options);
    return data;
  },
};

const UserGameStates: FC<{ user: AppUser & AppUserPublic }> = ({ user }) => {
  const [gameStates, setGameStates] = useState<{
    [x: string]: UserGameState;
  }>();
  var isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const lg = useMediaQuery({ maxWidth: 1600, minWidth: 800 });
  const xl = useMediaQuery({ minWidth: 1600 });
  const [activeGame, setActiveGame] = useState<string>();

  const columnSize = () => {
    if (xl) return 3;
    if (lg) return 2;
    if (isMobile) return 1;
    return 3;
  };

  useEffect(() => {
    const userRef = collection(db, generateFirestorePath("appUsers"));
    const userDoc = doc(userRef, user.uid);
    const gameStatesRef = collection(
      userDoc,
      generateFirestorePath("gameStates")
    ).withConverter(fireStoreConverter);

    const unsubGameStatesSnapshot = onSnapshot(gameStatesRef, (gameId) => {
      const gameStatesDef: typeof gameStates = {};
      for (const gameDefSnap of gameId.docs) {
        const data = gameDefSnap.data();
        gameStatesDef[data.gameId] = data;
      }
      setGameStates(gameStatesDef);
      setActiveGame(Object.keys(gameStatesDef)[0]);
    });

    return () => {
      unsubGameStatesSnapshot();
    };
  }, [user]);

  if (!gameStates || Object.keys(gameStates).length === 0 || !activeGame)
    return <></>;

  const currentGameState = gameStates[activeGame];
  return (
    <>
      <Title level={3}>Game States</Title>

      <Descriptions
        key={currentGameState.gameId}
        layout={!isMobile ? "horizontal" : "vertical"}
        column={columnSize()}
        bordered
        title={
          <Select
            style={{ width: 300 }}
            className="normal-select-without-padding"
            options={Object.values(gameStates).map((e) => {
              return {
                value: e.gameId,
                label: <GameName id={e.gameId} />,
              };
            })}
            onChange={setActiveGame}
            value={activeGame}
          />
        }
        className="pb-2"
      >
        <Descriptions.Item label="Elo V4">
          {currentGameState.eloV4}
        </Descriptions.Item>

        <Descriptions.Item label="Elo V4 Momentum">
          {currentGameState.eloV4Momentum}
        </Descriptions.Item>
        <Descriptions.Item label="Free Elo V4">
          {currentGameState.freeEloV4}
        </Descriptions.Item>
        <Descriptions.Item label="Free Elo V4 Momentum">
          {currentGameState.freeEloV4Momentum}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {dateFormatter(currentGameState.createdAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Updated At">
          {dateFormatter(currentGameState.updatedAt)}
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

export default UserGameStates;
