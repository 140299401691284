import { Divider } from "antd";
import { FC, useMemo } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
  ScriptableContext,
} from "chart.js";
import "chart.js/auto";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
);

function options(data: number | null) {
  return {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: "Blitz Multiplier",
      },
    },
    interaction: {
      intersect: false,
    },
    ...(data !== null && {
      elements: {
        point: {
          display: true,
          radius: (ctx: ScriptableContext<"line">) => {
            const index = ctx.dataIndex;
            const currentPoint = ctx.dataset.data[index];

            if (
              typeof currentPoint !== "number" &&
              currentPoint !== null &&
              data === currentPoint.y
            ) {
              return 10;
            }
            return 3;
          },
        },
      },
    }),
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: "Score",
        },
        suggestedMin: -10,
        suggestedMax: 200,
      },
      y: {
        display: true,
        title: {
          display: true,
          text: "Multiple",
        },
      },
    },
  };
}

interface Props {
  data: BlitzMultiplier[];
  score: number | null;
  multiple: number | null;
}
const BlitzMultiplierChart: FC<Props> = ({ data, score, multiple }) => {
  const dataSet = useMemo(() => {
    const blitzMultiplier = [...data];
    let filtered;
    if (multiple) {
      filtered = blitzMultiplier.find(
        (f) =>
          (f.score === score && multiple === f.multiple) ||
          multiple === f.multiple
      );
      if (!filtered && score !== null)
        blitzMultiplier.push({ score, multiple });
    }
    const sorted = blitzMultiplier.sort((a, b) => a.score - b.score);
    const labels = new Set(sorted.map((e) => e.score));
    return {
      labels: Array.from(labels),
      datasets: [
        {
          data: sorted.map((e) => ({ y: e.multiple, x: e.score })),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
          label: "Multiple",
          fill: false,
          tension: 0.4,
        },
      ],
    };
  }, [data, score, multiple]);

  const computeOptions = options(multiple);
  return (
    <div className="blitz-chart-div">
      <Divider />
      <Line options={computeOptions} data={dataSet} />
      <Divider />
    </div>
  );
};

export default BlitzMultiplierChart;
