import React, { useCallback, useEffect, useMemo } from "react";

interface IThemeSwitcherContext {
    currentTheme: string | undefined;
    switcher: ({ theme }: { theme: string }) => void;
}

const ThemeSwitcherContext = React.createContext<
    IThemeSwitcherContext | undefined
>(undefined);

interface Props {
    children?: React.ReactNode;
    defaultTheme?: string;
}

export function ThemeSwitcherProvider({
    defaultTheme,
    ...rest
}: Props) {
    const [currentTheme, setCurrentTheme] = React.useState<string>();

    const switcher = useCallback(
        ({ theme }: { theme: string }) => {
            if (theme === currentTheme) { return; }
            setCurrentTheme(theme);
            document.body.classList.remove("light-theme", "dark-theme");
            document.body.classList.add(`${theme}-theme`);
            if (window?.localStorage) {
                localStorage.setItem("theme", theme);
            }
        },
        [currentTheme]
    );

    useEffect(() => {
        if (defaultTheme) {
            switcher({ theme: defaultTheme });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultTheme]);

    const value = useMemo(
        () => ({
            currentTheme,
            switcher
        }),
        [switcher, currentTheme]
    );

    return <ThemeSwitcherContext.Provider value={value} {...rest} />;
}

export function useThemeSwitcher() {
    const context = React.useContext(ThemeSwitcherContext);
    if (!context) {
        throw new Error(
            'To use `useThemeSwitcher`, component must be within a ThemeSwitcherProvider'
        );
    }
    return context;
}