import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useState } from "react";

type Filter<T> = {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null>;
  sorter: SorterResult<T> | SorterResult<T>[];
};
const defaultValue = {
  filters: {},
  pagination: {},
  sorter: {},
};
export function useFilter<T>(
  key: string
): [filters: Filter<T>, setFilter: (data: Filter<T>) => void] {
  const getSavedData = () => {
    const data = localStorage.getItem(key);
    if (!data) return defaultValue;
    try {
      return JSON.parse(data);
    } catch (error) {
      console.log(error);
      return defaultValue;
    }
  };
  const [filter, setFilter] = useState<Filter<T>>(getSavedData());

  const setData = (data: typeof filter) => {
    setFilter(data);

    const str = JSON.stringify(data);

    localStorage.setItem(key, str);
  };

  return [filter, setData];
}
