import { Button, Col, Form, Input, Radio, Row } from "antd";
import { FC } from "react";
import { formSubmit } from "src/shared/config/constants";
import { SearchOutlined } from "@ant-design/icons";
import { SearchDataType } from "../search.types";
import { colGroupNames, getCollectionGroup } from "src/collections";
import { getDocs, query, where } from "firebase/firestore";

type FormType = {
  "tournament-search": string;
  type: "uid";
};

interface Props {
  isFetching: (t: boolean) => void;
  setData: (data: SearchDataType[]) => void;
}
const BlitzTournamentSearchBar: FC<Props> = ({ isFetching, setData }) => {
  const [form] = Form.useForm<FormType>();
  const handleSubmit = formSubmit(form);

  function onChange(field: keyof FormType, value: string) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [field]: value,
    });
  }

  const getBlitzTournamentById = async (id: string) => {
    const blitzTournamentsCol = getCollectionGroup(
      colGroupNames.blitzTournamentsV2
    );

    const blitzTournamentsQuery = query(
      blitzTournamentsCol,
      where("tournamentUid", "==", id)
    );

    const snapShot = await getDocs(blitzTournamentsQuery);

    let data: BlitzTournamentV2[] = [];
    snapShot.forEach((doc) => {
      if (doc.exists()) {
        data.push(doc.data());
      }
    });
    return data;
  };

  const onSave = async (data: FormType) => {
    isFetching(true);

    const response = await getBlitzTournamentById(data["tournament-search"]);

    if (response && response.length > 0) {
      setData(
        response.map((e) => ({
          title: e.status,
          description: `uid: ${e.tournamentUid || "-"}\n
           score: ${e.score}`,
          url: `/games/${e.gameId}/tournaments/blitz/${e.tournamentUid}`,
          icon: "/fallback_img.png",
        }))
      );
    }
    isFetching(false);
  };
  return (
    <Form
      name="app user search"
      form={form}
      autoComplete="off"
      onSubmitCapture={() => handleSubmit(onSave)}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="tournament-search"
            rules={[
              {
                required: true,
                message: "Search value is required",
              },
            ]}
          >
            <Input
              name="tournament-search"
              className="user-search-input-v2"
              size="middle"
              autoFocus={true}
              addonAfter={
                <SearchOutlined onClick={() => handleSubmit(onSave)} />
              }
              onChange={(event) =>
                onChange("tournament-search", event.target.value)
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="type" initialValue="uid">
            <Radio.Group
              name="type"
              onChange={(event) => onChange("type", event.target.value)}
            >
              <Radio value="uid">Search by UID</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Button type="primary" htmlType="submit" title="Search">
              Search
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default BlitzTournamentSearchBar;
