import {
  Button,
  Steps,
  Card,
  Col,
  Row,
  Typography,
  Carousel,
  Form,
  List,
} from "antd";
import { CarouselRef } from "antd/lib/carousel";
import { FC, useEffect, useRef, useState } from "react";
import { auth, axiosInstance, db, generateFirestorePath } from "src/helpers";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import FloatInput from "../../shared/components/base/FloatInput";
import { useNavigate } from "react-router-dom";
import { fetchUser } from "src/features/authentication/userSlice";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { adminUserConverter } from "src/converters";
import { Rule } from "antd/lib/form";

interface OrgRequest {
  orgName: string;
}

interface OrgCreateResult {
  status: "created-new";
  id: string;
  org: Organization;
}

const SplashPage: FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userState);
  const { orgs } = useAppSelector((state) => state.orgState);
  const slickRef = useRef<CarouselRef>();
  const [current, setCurrent] = useState(0);
  const [canContinue, setCanContinue] = useState(false);
  const [selectedOrg, setSelectedOrg] = useState<string | null>(
    user?.activeOrgId ?? null
  );
  const carouselMaxIdx = 1;
  const { Text, Title } = Typography;

  const { Step } = Steps;

  useEffect(() => {
    const adminUsersColRef = collection(
      db,
      generateFirestorePath("adminUsers")
    ).withConverter(adminUserConverter);
    const uid = auth.currentUser?.uid;
    if (!uid) {
      return;
    }

    const adminUserRef = doc(adminUsersColRef, uid);
    onSnapshot(adminUserRef, async () => {
      await dispatch(fetchUser());
    });
  }, [dispatch, selectedOrg]);

  function validateOrgInput() {
    const orgName: undefined | string = form.getFieldValue("orgName");

    if (!orgName) {
      setCanContinue(false);
    } else {
      setCanContinue(true);
    }
  }

  function onOrgFormChange(
    field: string | number | symbol,
    value: string | boolean | number | null
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [field]: value,
    });
    validateOrgInput();
  }

  const onboardingTitle = (
    <>
      <Row style={{ width: "100%" }} justify="space-between" align="middle">
        <Text>Get Started</Text>
      </Row>
    </>
  );

  function validOrgName(_: Rule, orgName: string) {
    const orgNames = Object.values(orgs).map((org) => org.name);

    const isDupName = orgNames.find((elem) => elem === orgName);
    if (orgName === "") {
      return Promise.reject("Must provide organization name.");
    } else if (isDupName) {
      return Promise.reject("Organization already exists!");
    }
    return Promise.resolve();
  }

  function validOrgForm(formVals: any): formVals is OrgRequest {
    const { orgName } = formVals;

    if (typeof orgName !== "string" || orgName === "") {
      return false;
    }

    return true;
  }

  async function setupAccount() {
    const newOrgReq = form.getFieldsValue();

    if (validOrgForm(newOrgReq)) {
      try {
        const newOrgRequestBody = {
          orgName: newOrgReq.orgName,
        };
        // first create the org
        const {
          data: { id },
        } = await axiosInstance.post<OrgCreateResult>(
          "/admin/orgs",
          newOrgRequestBody
        );
        setSelectedOrg(id);
      } catch (error) {
        console.error(error);
      }
    }
  }

  function getSummarySrc() {
    const data = ["Organization Name:  " + form.getFieldValue("orgName")];
    return data;
  }

  return (
    <>
      <Row justify="center">
        <Col span={24}>
          <Card
            title={onboardingTitle}
            headStyle={{ borderWidth: 0, fontSize: 28 }}
            bordered={false}
            style={{
              width: "90%",
              marginLeft: "5%",
              background: "rgb(31,31,31)",
              boxShadow: "0px 3px 4px 3px rgba(208, 216, 243, 0.2)",
            }}
          >
            <Steps className="pb-2" current={current}>
              <Step title="Create an Organization" />
              <Step title="Review" />
            </Steps>

            <Form
              className="pt-2"
              name="app-config"
              form={form}
              colon={false}
              autoComplete="off"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 12 }}
              requiredMark={"optional"}
            >
              <Carousel
                style={{ borderWidth: "0px" }}
                dots={false}
                beforeChange={(_, to) => {
                  setCurrent(to);
                }}
                ref={(ref) => {
                  if (ref) {
                    slickRef.current = ref;
                  }
                }}
              >
                <Row className="pb-2 pt-2" justify="center">
                  <Col span={24}>
                    <Form.Item
                      className="app-config-input-wrap"
                      label={<span />}
                      name="orgName"
                      rules={[
                        {
                          required: true,
                          message: "Organization/Company Name is required",
                          validator: validOrgName,
                        },
                      ]}
                    >
                      <FloatInput
                        name="orgName"
                        placeholder="Organization/Company Name"
                        label="Organization/Company Name"
                        value={form.getFieldValue("orgName")}
                        size="large"
                        onChange={(event) =>
                          onOrgFormChange("orgName", event.target.value)
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row className="pb-2" justify="center">
                  <Col span={24}>
                    <Row className="pb-2 pt-2" justify="center">
                      <List
                        size="large"
                        header={<Title level={4}>Summary</Title>}
                        bordered
                        dataSource={getSummarySrc()}
                        renderItem={(item) => <List.Item>{item}</List.Item>}
                      />
                    </Row>
                  </Col>
                </Row>
              </Carousel>

              <Row justify={current === 0 ? "end" : "space-between"}>
                {current !== 0 && (
                  <Button
                    onClick={() => {
                      if (slickRef.current) {
                        slickRef.current.prev();
                      }
                    }}
                  >
                    Back
                  </Button>
                )}

                <Button
                  type="primary"
                  disabled={!canContinue}
                  onClick={async () => {
                    if (current !== carouselMaxIdx) {
                      if (slickRef.current) {
                        slickRef.current.next();
                      }
                    } else {
                      // DONE
                      await setupAccount();
                      navigate("/");
                    }
                  }}
                >
                  {current === carouselMaxIdx ? "Done" : "Next"}
                </Button>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SplashPage;
