import { separateByCapitalLetters } from "src/shared/config/constants";

export function createMetaDataArray(trx?: unknown) {
  if (!trx) return [];
  if (!(typeof trx === "object" && "metadata" in trx)) return [];
  const transactionDetails = { ...trx } as { metadata: unknown };
  const metaData = transactionDetails.metadata;
  if (!metaData) return [];
  if (Array.isArray(metaData))
    return [{ label: "metadata", value: JSON.stringify(metaData) }];
  if (typeof metaData !== "object") return [];
  return Object.entries(metaData).map(([key, val]) => {
    const label = separateByCapitalLetters(key);
    let value: string;
    switch (typeof val) {
      case "boolean":
        value = val ? "Yes" : "No";
        break;
      case "string":
        value = val;
        break;
      case "number":
        value = val.toFixed(2);
        break;
      case "object":
        value = JSON.stringify(val);
        break;
      default:
        value = "";
        break;
    }

    return { label, value };
  });
}
