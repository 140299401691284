import { FC } from "react";
import { useAppSelector } from "src/app/hooks";

interface Props {
  id: string;
}

/** V2 of GameName component that will fetch all games irrespective of selected org */
const GameNameV2: FC<Props> = ({ id }) => {
  const { listOfAllGames } = useAppSelector((state) => state.gameState);

  if (id === "none") return <>None</>;

  const selectedGame = listOfAllGames.find((f) => f.id === id);

  if (selectedGame) {
    return <>{selectedGame.name}</>;
  }
  return <></>;
};

export default GameNameV2;
