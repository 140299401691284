import { FC, useEffect, useState } from "react";
import {
  Tag,
  Descriptions,
  Skeleton,
  Row,
  Avatar,
  Typography,
  Empty,
} from "antd";
import { dateFormatter } from "src/shared/config/constants";
import { UserOutlined } from "@ant-design/icons";
import Currency from "src/shared/components/currency/Currency";
import BlitzMultiplierChart from "./components/BlitzMultiplierChart";
import { useNavigate } from "react-router-dom";
import GameName from "src/shared/components/GameName/gameName";
import { db, fixDigits, getPhoto, isTriumphEmployee } from "src/helpers";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { blitzTournamentConverter } from "src/converters";
import { createMetaDataArray } from "./utils/utils";

const { Title, Link } = Typography;

interface Props {
  row: FinishBlitzTournamentTrxV2 | null;
  user: AppUserPublic;
}

const FinishBlitzTournament: FC<Props> = ({ row: trx, user }) => {
  const navigate = useNavigate();

  const [blitzTournament, setTournamentDetails] =
    useState<BlitzTournamentV2 | null>(null);

  useEffect(() => {
    let unsub: Unsubscribe | undefined;
    async function setup() {
      if (trx) {
        const { tournamentId, gameId } = trx;
        const gamesCol = collection(db, "games");
        const gameRef = doc(gamesCol, gameId);
        const tournamentsCol = collection(
          gameRef,
          "blitzTournamentsV2"
        ).withConverter(blitzTournamentConverter);
        const tournamentRef = doc(tournamentsCol, tournamentId);
        unsub = onSnapshot(
          tournamentRef,
          (snap) => {
            const data = snap.data();
            if (data) {
              setTournamentDetails(data);
            }
          },
          (error) => console.error(error.message)
        );
      }
    }

    setup();
    return () => {
      unsub?.();
    };
  }, [trx]);

  if (!trx || !blitzTournament) return <Skeleton title paragraph />;

  if (
    !blitzTournament ||
    !(blitzTournament.version === "2" || blitzTournament.version === "2.4")
  )
    return <Empty />;
  return (
    <>
      <Descriptions
        title="Tournament Details"
        layout="horizontal"
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="Tournament Definition ID">
          {blitzTournament.tournamentDefinitionId || "null"}
        </Descriptions.Item>
        <Descriptions.Item label="Score">
          {blitzTournament.score || "null"}
        </Descriptions.Item>
        {blitzTournament.status === "finished" && (
          <Descriptions.Item label="Multiplier">
            {blitzTournament.multiplier
              ? fixDigits(blitzTournament.multiplier, 4)
              : "null"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Type">
          <Tag color="magenta">{blitzTournament.type || "null"}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Percentile">
          {blitzTournament.percentile
            ? ((blitzTournament.percentile || 0) * 100).toFixed(2)
            : "null"}
        </Descriptions.Item>
        <Descriptions.Item label="Seed">
          {blitzTournament.seed || "null"}
        </Descriptions.Item>
        <Descriptions.Item label="Version">
          {blitzTournament.version || "null"}
        </Descriptions.Item>
        <Descriptions.Item label="UID">
          {blitzTournament.uid || "null"}
        </Descriptions.Item>
        {isTriumphEmployee() && (
          <Descriptions.Item label="Game ID">
            {blitzTournament.gameId || "null"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Payout">
          {blitzTournament.payout ? (
            <Currency
              value={blitzTournament.payout}
              prefix="$"
              currency="cents-to-dollar"
            />
          ) : (
            "null"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Status">
          <Tag color="lime"> {blitzTournament.status || "null"}</Tag>
        </Descriptions.Item>

        <Descriptions.Item label="Archived">
          {blitzTournament.tournamentDefinition.archived !== undefined
            ? blitzTournament.tournamentDefinition.archived
              ? "Yes"
              : "No"
            : "null"}
        </Descriptions.Item>
        <Descriptions.Item label="Entry Price">
          {blitzTournament.tournamentDefinition.entryPrice ? (
            <Currency
              value={blitzTournament.tournamentDefinition.entryPrice}
              prefix="$"
              currency="cents-to-dollar"
            />
          ) : (
            "null"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Max Tokens">
          {blitzTournament.tournamentDefinition.maxTokens || "null"}
        </Descriptions.Item>
        <Descriptions.Item label="Created At">
          {blitzTournament.createdAt
            ? dateFormatter(blitzTournament.createdAt)
            : "null"}
        </Descriptions.Item>
        <Descriptions.Item label="Finished At">
          {blitzTournament.finishedAt
            ? dateFormatter(blitzTournament.finishedAt)
            : "null"}
        </Descriptions.Item>
      </Descriptions>
      <BlitzMultiplierChart
        multiple={blitzTournament.multiplier}
        score={blitzTournament.score}
        data={blitzTournament.blitzMultipliers}
      />
      <Row justify="center">
        <Avatar size={100} src={getPhoto(user)}>
          <UserOutlined />
        </Avatar>
      </Row>
      <Row justify="center">
        <Title level={4}>{user.username || "null"}</Title>
      </Row>

      <Descriptions
        title="Transaction Details"
        layout="horizontal"
        colon
        column={1}
        bordered
        className="pb-2"
      >
        <Descriptions.Item label="ID">{trx.uid || "null"}</Descriptions.Item>
        <Descriptions.Item label="Trace ID">
          {trx.traceId || "null"}
        </Descriptions.Item>

        <Descriptions.Item label="App User username">
          {user.username || "null"}
        </Descriptions.Item>
        <Descriptions.Item label="App User ID">
          {trx.appUserUid || "null"}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          <Tag color="blue">{trx.type || "null"}</Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          {trx.description || "null"}
        </Descriptions.Item>
        <Descriptions.Item label="Amount">
          {trx.amount ? (
            <Currency
              currency="cents-to-dollar"
              prefix="$"
              value={trx.amount}
            />
          ) : (
            "null"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Token Amount">
          {trx.tokenAmount ? (
            <Currency
              currency="cents-to-dollar"
              prefix="$"
              value={trx.tokenAmount}
            />
          ) : (
            "null"
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Bonus Cash Amount">
          {trx.bonusCashAmount ? (
            <Currency
              currency="cents-to-dollar"
              prefix="$"
              value={trx.bonusCashAmount}
            />
          ) : (
            "null"
          )}
        </Descriptions.Item>
        {isTriumphEmployee() && (
          <Descriptions.Item label="Game ID">
            {trx.gameId || "null"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label="Game">
          <GameName id={trx.gameId || "null"} />
        </Descriptions.Item>
        <Descriptions.Item label="Tournament ID">
          <Link
            onClick={() =>
              navigate(
                `/games/${trx.gameId}/tournaments/blitz/${trx.tournamentId}`
              )
            }
          >
            {trx.tournamentId || "null"}
          </Link>
        </Descriptions.Item>
        <Descriptions.Item label="Created Date">
          {trx.createdAt ? dateFormatter(trx.createdAt) : "null"}
        </Descriptions.Item>
        <Descriptions.Item label="Transaction Date">
          {trx.transactionDate ? dateFormatter(trx.transactionDate) : "null"}
        </Descriptions.Item>
        {createMetaDataArray(trx).map((e) => (
          <Descriptions.Item label={e.label}>{e.value}</Descriptions.Item>
        ))}
      </Descriptions>
    </>
  );
};

export default FinishBlitzTournament;
