import { Col, Typography } from "antd";
import { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const LoadingTriumph: FC<{ isLoading: boolean; noLoadingText?: string }> = ({
  isLoading,
  noLoadingText,
}) => {
  return (
    <div className="sign-in-container">
      <div className="login-form">
        <Col>
          <img
            className="triumph-logo"
            src="/Logo_Gradient.png"
            alt="Triumph Logo"
            width={64}
          />
        </Col>
        <Col>
          <Typography.Title level={2}>Triumph Dashboard</Typography.Title>
        </Col>
        <Col className="pb-2">
          {!isLoading ? (
            <Typography.Text>{noLoadingText}</Typography.Text>
          ) : (
            <LoadingOutlined size={64} />
          )}
        </Col>
      </div>
    </div>
  );
};

export default LoadingTriumph;
