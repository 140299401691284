import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { doc, getDoc } from "firebase/firestore";
import { colNames, getCollection } from "src/collections";
/* type imports */
import type { RootState } from "src/app/store";

interface AdminUserState {
  users: { [x: string]: AdminUser | undefined };
  isFetchingUserById: boolean;
}

const initialState: AdminUserState = {
  users: {},
  isFetchingUserById: false,
};

export const getAdminUserById = createAsyncThunk(
  "user/getAdminUserById",
  async (id: string) => {
    try {
      const adminUserColRef = getCollection(colNames.adminUsers);

      const adminUserDocRef = doc(adminUserColRef, id);

      const docData = await getDoc(adminUserDocRef);
      const data = docData.data();
      return data;
    } catch (error) {
      throw error;
    }
  },
  {
    condition: (
      id,
      { getState, extra }: { getState: () => RootState; extra: any }
    ) => {
      if (!id) return false;
      const { adminUserState } = getState();
      const existing = adminUserState.users?.[id];
      if (existing) return false;
      return true;
    },
  }
);

const adminUserSlice = createSlice({
  name: "adminUser",
  reducers: {},
  initialState: initialState,
  extraReducers: (builder) => {
    builder.addCase(getAdminUserById.pending, (state, action) => {
      if (state.isFetchingUserById === false) state.isFetchingUserById = true;
    });
    builder.addCase(getAdminUserById.fulfilled, (state, action) => {
      if (state.isFetchingUserById === true) state.isFetchingUserById = false;
      const id = action.meta.arg || action.payload?.uid;
      if (id) state.users[id] = action.payload;
    });
    builder.addCase(getAdminUserById.rejected, (state, action) => {
      if (state.isFetchingUserById === true) state.isFetchingUserById = false;
    });
  },
});

export const adminUserSelect = (state: RootState) => state.adminUserState;

export default adminUserSlice.reducer;
