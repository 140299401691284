import React, { FC, useState } from "react";
import { Button, Col, Form, Modal, Row } from "antd";
import { axiosInstance } from "src/helpers";
import { useNavigate } from "react-router-dom";
import { RuleObject } from "antd/lib/form";
import { useAppSelector } from "src/app/hooks";
import FloatInput from "../../shared/components/base/FloatInput";

interface OrgCreateResult {
  status: "created-new";
  id: string;
  org: Organization;
}

interface NewOrgParams {
  open: boolean;
  setSelectedOrg: React.Dispatch<React.SetStateAction<string | null>>;
  setAddingOrg: React.Dispatch<React.SetStateAction<boolean>>;
}

const NewOrg: FC<NewOrgParams> = ({ open, setSelectedOrg, setAddingOrg }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { orgs } = useAppSelector((state) => state.orgState);
  const orgName = Form.useWatch<string>("orgName", form);
  const [loading, setLoading] = useState<boolean>(false);

  function validOrgName(rule: RuleObject, orgName: string) {
    const orgNames = Object.values(orgs).map((org) => org.name);

    const isDupName = orgNames.find((elem) => elem === orgName);
    if (orgName === "") {
      return Promise.reject("Must provide organization name.");
    } else if (isDupName) {
      return Promise.reject("Organization already exists!");
    }
    return Promise.resolve();
  }

  async function addNewOrg() {
    setLoading(true);

    try {
      const newOrgRequestBody = {
        orgName,
      };

      const res = await axiosInstance.post<OrgCreateResult>(
        "/admin/orgs",
        newOrgRequestBody
      );
      setSelectedOrg(res.data.id);
      setAddingOrg(false);
      navigate("/");
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }

  const modalContent = (
    <>
      <Form name="app config" form={form} autoComplete="off">
        <Row>
          <Col span={24}>
            <Form.Item
              className="app-config-input-wrap"
              name="orgName"
              rules={[
                {
                  required: true,
                  message: "Organization/Company Name is required",
                  validator: validOrgName,
                },
              ]}
            >
              <FloatInput
                name="orgName"
                placeholder="Organization/Company Name"
                label="Organization/Company Name"
                value={form.getFieldValue("orgName")}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );

  return (
    <>
      <Modal
        title="Create New Organization"
        centered
        closable={false}
        open={open}
        onCancel={() => {
          setAddingOrg(false);
          form.resetFields();
        }}
        width={1000}
        footer={[
          <Button
            type="primary"
            key="submit"
            loading={loading}
            onClick={async () => {
              await addNewOrg();
              form.resetFields();
            }}
          >
            Save
          </Button>,
        ]}
      >
        <>{modalContent}</>
      </Modal>
    </>
  );
};

export default NewOrg;
