import { FC } from "react";
import { Modal, Skeleton } from "antd";
import UserDetails from "src/components/userDetail/userDetails";

interface Props {
  user: string | null;
  onClose: () => void;
}
const UserInfoExpanded: FC<Props> = ({ onClose, user: selectedUser }) => {
  function onCloseDialog() {
    onClose();
  }
  return (
    <>
      <Modal
        title="User Details"
        centered
        open={!!selectedUser}
        onOk={onCloseDialog}
        onCancel={onCloseDialog}
        width={"95vw"}
        key={selectedUser}
      >
        {selectedUser ? (
          <UserDetails expandedUser={selectedUser} />
        ) : (
          <Skeleton avatar title paragraph />
        )}
      </Modal>
    </>
  );
};

export default UserInfoExpanded;
