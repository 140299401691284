import { Col, Form, message, Modal, Row, Select } from "antd";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { getOrganizations } from "src/features/orgs/orgSlice";
import { axiosInstance } from "src/helpers";
import FloatInput from "src/shared/components/base/FloatInput";

interface GameAddResult {
  status: "created-new";
  gameId: string;
  game: Game;
}

interface NewGameRequest {
  type: "ios" | "unity";
  displayName: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
}

const CreateGame: FC<Props> = ({ open, onClose }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userState);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (open) {
      setIsModalOpen(true);
    }
  }, [open]);

  function onGameFormChange(
    field: string | number | symbol,
    value: string | boolean | number | null
  ) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [field]: value,
    });
  }

  async function createGame() {
    setIsLoading(true);
    try {
      const { displayName, type } = form.getFieldsValue();
      form.submit();
      if (validNewGameForm({ displayName, type })) {
        const headers = {
          "Content-Type": "application/json",
        };

        // create new game
        await axiosInstance.post<GameAddResult>(
          `/admin/orgs/${user?.activeOrgId}/add_game?platform=${type}`,
          { displayName },
          {
            headers: headers,
          }
        );

        await dispatch(getOrganizations());

        setIsModalOpen(false);
        onClose();
        form.resetFields();
      }
    } catch (error) {
      message.error("failed to create game");
    } finally {
      setIsLoading(false);
    }
  }

  function validNewGameForm(formVals: any): formVals is NewGameRequest {
    const { type, displayName } = formVals;
    const valsArr = [type, displayName];

    const isMissingVal = valsArr.some((val) => val == null); // true if any form val is undefined or null
    const containsEmptyVal = valsArr.some((val) => val === ""); // true if any form vals are empty string
    const invalidGameType = type !== "ios" && type !== "unity"; // true if receive game type other than 'web' or 'ios'

    if (isMissingVal || containsEmptyVal || invalidGameType) {
      return false;
    }

    return true;
  }

  function onCancel() {
    setIsModalOpen(false);
    setIsLoading(false);
    onClose();
    form.resetFields();
  }

  return (
    <>
      <Modal
        title="Create New Game"
        centered
        open={isModalOpen}
        okText={user?.activeOrgId && "Create"}
        confirmLoading={isLoading}
        onOk={user?.activeOrgId ? createGame : onCancel}
        onCancel={onCancel}
      >
        {user && user.activeOrgId ? (
          <Row className="pb-2" justify="center">
            <Col span={24}>
              <Form
                className="pt-2"
                name="app-config"
                form={form}
                colon={false}
                autoComplete="off"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 12 }}
                requiredMark={"optional"}
              >
                <Row className="pb-2 pt-2" justify="center">
                  <Col span={24}>
                    <Form.Item
                      label={<span />}
                      name="displayName"
                      rules={[
                        {
                          required: true,
                          message: "Game title is required",
                        },
                      ]}
                    >
                      <FloatInput
                        name="displayName"
                        size="middle"
                        placeholder="Game Title"
                        label="Game Title"
                      />
                    </Form.Item>

                    <Form.Item
                      label={<span />}
                      name="type"
                      rules={[
                        {
                          required: true,
                          message: "Game type is required",
                        },
                      ]}
                    >
                      <Select
                        className="large-select-center-aligned"
                        placeholder={"Select Game Type"}
                        onChange={(val) => {
                          onGameFormChange("type", val);
                        }}
                      >
                        <Select.Option value="ios">iOS</Select.Option>
                        <Select.Option value="unity">Unity</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        ) : (
          <Row justify="center">
            You cannot create a game if you don't belong to any organization.
            Please create/select an organization first.
          </Row>
        )}
      </Modal>
    </>
  );
};

export default CreateGame;
