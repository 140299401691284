import { FC, useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { axiosInstance } from "src/helpers";
import { useAppSelector } from "src/app/hooks";
import LoadingTriumph from "./Loading";

const Join: FC = () => {
  const { token } = useParams();
  const [error, setError] = useState(false);
  const { user, isfetchingUser } = useAppSelector((state) => state.userState);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    async function joinOrg() {
      try {
        if (!user) {
          return;
        }
        if (!token || typeof token !== "string") {
          return;
        }

        /** token formated as `{{memberInviteUid}}+{{orgUid}}' */
        const [inviteId, orgId] = token.split("+");
        const {
          data: { success },
        } = await axiosInstance.post<{ success: boolean }>(
          `/admin/orgs/${orgId}/join`,
          {
            inviteId,
            email: user.email,
          }
        );
        if (!success) {
          setError(true);
        }
        setIsLoading(false);
      } catch (error) {
        setError(true);
      }
    }

    joinOrg();
  }, [token, user]);

  if (!user && !isfetchingUser) {
    return <Navigate to={"/sign-in"} state={{ token }} />;
  }
  if (error) {
    return (
      <LoadingTriumph
        isLoading={false}
        noLoadingText="Unable to join organization."
      />
    );
  }

  if (isLoading) {
    return <LoadingTriumph isLoading />;
  } else {
    return <Navigate to="/" />;
  }
};

export default Join;
