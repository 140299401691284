import { Button, Col, Form, Input, Radio, Row } from "antd";
import { FC } from "react";
import { formSubmit } from "src/shared/config/constants";
import { SearchOutlined } from "@ant-design/icons";
import { SearchDataType } from "../search.types";
import { colNames, getCollection } from "src/collections";
import { getDocs, query, where } from "firebase/firestore";
import { getPhoto } from "src/helpers";

type FormType = {
  "user-search": string;
  type: "username" | "name" | "phoneNumber" | "uid";
};

interface Props {
  isFetching: (t: boolean) => void;
  setData: (data: SearchDataType[]) => void;
}
const UserSearchBar: FC<Props> = ({ isFetching, setData }) => {
  const [form] = Form.useForm<FormType>();
  const handleSubmit = formSubmit(form);

  function onChange(field: keyof FormType, value: string) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [field]: value,
    });
  }

  const fetchUser = async (
    value: string,
    type: FormType["type"]
  ): Promise<(AppUser & AppUserPublic) | undefined> => {
    const search = value.trim();
    const userColRef = getCollection(colNames.appUsers);
    const publicUserColRef = getCollection(colNames.appUsersPublic);
    console.log(value, type);
    if (["uid", "name", "username"].includes(type)) {
      const publicUserQuery = query(
        publicUserColRef,
        where(type, "==", search)
      );

      const publicUserSnapshot = await getDocs(publicUserQuery);

      let data = {
        ...publicUserSnapshot.docs?.[0]?.data(),
      };

      const privateUserQuery = query(userColRef, where("uid", "==", data.uid));
      const privateUserSnapshot = await getDocs(privateUserQuery);

      return { ...data, ...privateUserSnapshot.docs?.[0]?.data() };
    }

    if (type === "phoneNumber") {
      const privateUserQuery = query(
        userColRef,
        where("phoneNumber", "==", search)
      );
      const privateUserSnapshot = await getDocs(privateUserQuery);
      let data = {
        ...privateUserSnapshot.docs?.[0]?.data(),
      };

      const publicUserQuery = query(
        publicUserColRef,
        where("uid", "==", data.uid)
      );
      const publicUserSnapshot = await getDocs(publicUserQuery);

      return { ...data, ...publicUserSnapshot.docs?.[0]?.data() };
    }
  };

  const onSave = async (data: FormType) => {
    isFetching(true);
    try {
      const response = await fetchUser(data["user-search"], data.type);

      if (response && Object.keys(response).length !== 0) {
        setData([
          {
            title: response.username,
            description: `username: ${response.username || "-"}\n
        uid: ${response.uid}`,
            url: `users/${response.uid}`,
            icon: getPhoto(response),
          },
        ]);
      }
    } catch (error) {
    } finally {
      isFetching(false);
    }
  };
  return (
    <Form
      name="app user search"
      form={form}
      autoComplete="off"
      onSubmitCapture={() => handleSubmit(onSave)}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="user-search"
            rules={[
              {
                required: true,
                message: "Search value is required",
              },
            ]}
          >
            <Input
              name="user-search"
              className="user-search-input-v2"
              size="middle"
              autoFocus={true}
              addonAfter={
                <SearchOutlined onClick={() => handleSubmit(onSave)} />
              }
              onChange={(event) => onChange("user-search", event.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="type" initialValue="username">
            <Radio.Group
              name="type"
              onChange={(event) => onChange("type", event.target.value)}
            >
              <Radio value="username">Search by Username</Radio>
              <Radio value="name">Search by Name</Radio>
              <Radio value="phoneNumber">Search by Phone</Radio>
              <Radio value="uid">Search by UID</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Button type="primary" htmlType="submit" title="Search">
              Search
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default UserSearchBar;
