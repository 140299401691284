import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { documentId, getDocs, query, where } from "firebase/firestore";
import { colNames, getCollection } from "src/collections";
/* type imports */
import type { RootState } from "src/app/store";

interface AppUserPublicState {
  loading: "idle" | "fetching" | "finish";
  usersPublic: { [x: string]: AppUserPublic };
  errors: { [x: string]: string | undefined };
}

const initialState: AppUserPublicState = {
  loading: "idle",
  usersPublic: {},
  errors: {},
};

export const getAppUserPublicDetails = createAsyncThunk(
  "appUser/getAppUserPublicDetails",
  async (id: string) => {
    try {
      const publicUserColRef = getCollection(colNames.appUsersPublic);

      const publicUserQuery = query(
        publicUserColRef,
        where(documentId(), "==", id)
      );

      const publicUserSnapshot = await getDocs(publicUserQuery);

      const data = publicUserSnapshot.docs[0].data();

      return data;
    } catch (error) {
      throw error;
    }
  },
  {
    condition: (
      id,
      { getState }: { getState: () => RootState; extra: any }
    ) => {
      if (!id) return false;
      const {
        appUserPublicState: { usersPublic },
      } = getState();
      return !(id in usersPublic);
    },
  }
);

export const getAppUserPublicArrayDetails = createAsyncThunk(
  "appUser/getAppUserPublicArrayDetails",
  async (ids: string[]) => {
    try {
      const publicUserColRef = getCollection(colNames.appUsersPublic);
      const data = (
        await Promise.all(
          ids.map((id) =>
            getDocs(query(publicUserColRef, where(documentId(), "==", id)))
          )
        )
      )
        .map((queryResults) => queryResults.docs)
        .flat()
        .map((appUserPublicSnap) => appUserPublicSnap.data());

      return data;
    } catch (error) {
      throw error;
    }
  },
  {
    condition: (
      ids,
      { getState }: { getState: () => RootState; extra: any }
    ) => {
      if (ids.length === 0) return false;
      const {
        appUserPublicState: { usersPublic },
      } = getState();
      for (const id of ids) {
        if (!(id in usersPublic)) {
          return true;
        }
      }
      return false;
    },
  }
);

const appUserPublicSlice = createSlice({
  name: "appUserPublic",
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAppUserPublicDetails.pending, (state) => {
      state.loading = "fetching";
    });
    builder.addCase(getAppUserPublicDetails.fulfilled, (state, action) => {
      state.loading = "finish";
      state.usersPublic[action.payload.uid] = action.payload;
    });
    builder.addCase(getAppUserPublicDetails.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(getAppUserPublicArrayDetails.pending, (state) => {
      state.loading = "fetching";
    });
    builder.addCase(getAppUserPublicArrayDetails.fulfilled, (state, action) => {
      state.loading = "finish";
      for (const user of action.payload) {
        state.usersPublic[user.uid] = user;
      }
    });
    builder.addCase(getAppUserPublicArrayDetails.rejected, (state) => {
      state.loading = "idle";
    });
  },
});

export const appUserPublicSelect = (state: RootState) =>
  state.appUserPublicState;

export default appUserPublicSlice.reducer;
