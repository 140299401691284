import React, { useState } from "react";
// import { Redirect } from 'react-router-dom';
import {
  Button,
  Col,
  Divider,
  Input,
  Layout,
  Row,
  Tag,
  Typography,
} from "antd";
import { GoogleOutlined, BackwardFilled } from "@ant-design/icons";
import {
  GoogleAuthProvider,
  signInWithPopup,
  getAuth,
  sendSignInLinkToEmail,
} from "firebase/auth";
import { Navigate, useLocation } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import { auth, Logger } from "src/helpers";
import { useAppDispatch } from "src/app/hooks";
import { fetchUpsertUser } from "src/features/authentication/userSlice";
import { EMAIL_REGEX } from "src/shared/config/constants";
import LoadingTriumph from "./Loading";
/** type imports */

const { Content } = Layout;
const SignIn: React.FC = () => {
  const location = useLocation();
  const { token } = location.state;
  const dispatch = useAppDispatch();
  const { user, isfetchingUser } = useAppSelector((state) => state.userState);
  const [email, setEmail] = useState("");
  const [isGmail, setIsGmail] = useState(true);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const provider = new GoogleAuthProvider();
  const reset = () => {
    setIsEmailSent(false);
    setIsLoading(false);
    setEmail("");
    setError("");
  };

  const prevPath = location.state as {
    from: { pathname: string; search: string };
  };
  if (isfetchingUser && user === null) return <LoadingTriumph isLoading />;
  if (user) {
    // if user is signing in or creating an account using a join org token
    // first add them to org and then redirect
    if (token && typeof token === "string") {
      return <Navigate to={`/join/${token}`} />;
    }
    return (
      <Navigate
        to={
          prevPath.from.pathname + prevPath.from.search ||
          "/" + prevPath.from.search
        }
      />
    );
  }

  if (isEmailSent) {
    return (
      <div className="sign-in-container">
        <div className="login-form">
          <Col>
            <img
              className="triumph-logo"
              src="/Logo_Gradient.png"
              alt="Triumph Logo"
              width={64}
            />
          </Col>
          <Col>
            <Typography.Title level={2}>Triumph Dashboard</Typography.Title>
          </Col>
          <Col className="pb-2">
            <Typography.Text>
              Please check your inbox for sign-in link.
            </Typography.Text>
            <Typography.Link onClick={reset}>
              {" "}
              Try another email
            </Typography.Link>
          </Col>
        </div>
      </div>
    );
  }

  console.log("HI MOM " + process.env.REACT_APP_ENVIRONMENT);

  const onSignin = () => {
    setError("");
    if (!EMAIL_REGEX.test(email)) {
      setError("Please enter accurate email address.");
      return;
    }

    const baseDeepLink = `${window.location.origin}/magic-link/${email}`;
    let url;
    // if user has a join org token, add as a query param
    if (token && typeof token === "string") {
      const encodedToken = token.replace("+", "%2B");
      url = baseDeepLink + `?token=${encodedToken}`;
    } else {
      url = baseDeepLink;
    }
    const actionCodeSettings = {
      url,
      handleCodeInApp: true,
    };

    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, actionCodeSettings)
      .then(() => {
        setIsEmailSent(true);
      })
      .catch((error) => {
        setError("Unknown error has occured");
        Logger("SignIn.tsx", "sendSignInLinkToEmail", error);
      });

    setIsLoading(true);
  };

  return (
    <Layout>
      <Content>
        <div className="sign-in-container">
          <div className="login-form">
            <Col>
              <img
                className="triumph-logo"
                src="/Logo_Gradient.png"
                alt="Triumph Logo"
                width={64}
              />
            </Col>
            <Col>
              <Typography.Title level={2}>Welcome</Typography.Title>
            </Col>
            <Col className="pb-2">
              <Typography.Text>Login to Triumph Dashboard</Typography.Text>
            </Col>
            {!isGmail ? (
              <>
                <Col className="pb-2">
                  <Input
                    className="w-100"
                    size="large"
                    placeholder="example@email.com"
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                  />
                </Col>
                <Col>
                  <Button
                    onClick={onSignin}
                    type="primary"
                    className="w-100 pb-2"
                    loading={isLoading}
                  >
                    Sign in with Email
                  </Button>
                </Col>
                {error && (
                  <Col className="pt-2 pb-2">
                    <Tag color="red">{error}</Tag>
                  </Col>
                )}

                <Col className="pt-3">
                  <Typography.Link onClick={() => setIsGmail(true)}>
                    <BackwardFilled /> Back to gmail
                  </Typography.Link>
                </Col>
              </>
            ) : (
              <>
                <Col className="pt-2">
                  <Row justify="center">
                    <Button
                      size="large"
                      onClick={async () => {
                        try {
                          /** Sign-In With Google */
                          const result = await signInWithPopup(auth, provider);
                          const user = result.user.toJSON() as FirebaseAuthUser;
                          await dispatch(fetchUpsertUser(user));
                        } catch (error) {
                          Logger("SignIn.tsx", "onClick", error);
                        }
                      }}
                      type="primary"
                      icon={<GoogleOutlined />}
                    >
                      Sign in with google
                    </Button>
                  </Row>
                </Col>

                <Col>
                  <Divider />
                  OR
                </Col>
                <Typography.Link onClick={() => setIsGmail(false)}>
                  Use another email
                </Typography.Link>
              </>
            )}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default SignIn;
