import { Descriptions } from "antd";
import { FC } from "react";
import { useMediaQuery } from "react-responsive";
import Currency from "src/shared/components/currency/Currency";

const UserStatistics: FC<{ statsData?: UserStatisticsAmountsResponse }> = ({
  statsData,
}) => {
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const lg = useMediaQuery({ maxWidth: 1600, minWidth: 800 });
  const xl = useMediaQuery({ minWidth: 1600 });
  const columnSize = () => {
    if (xl) return 3;
    if (lg) return 2;
    if (isMobile) return 1;
    return 3;
  };

  if (!statsData) return <></>;
  return (
    <Descriptions
      layout={!isMobile ? "horizontal" : "vertical"}
      column={columnSize()}
      bordered
      title="User Statistics"
      className="pb-2"
    >
      <Descriptions.Item label="Total Deposits">
        <Currency
          currency="cents-to-dollar"
          prefix="$"
          value={statsData.totalDeposits}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Total Withdrawals">
        <Currency
          currency="cents-to-dollar"
          prefix="$"
          value={statsData.totalWithdrawals}
        />
      </Descriptions.Item>
      <Descriptions.Item label="User Profit & Loss">
        <Currency
          currency="cents-to-dollar"
          prefix="$"
          value={statsData.userPL}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Amount Triumph Deposits">
        <Currency
          currency="cents-to-dollar"
          prefix="$"
          value={statsData.amountTriumphDeposits}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Number of Triumph Deposits">
        {statsData.numberTriumphDeposits}
      </Descriptions.Item>
      <Descriptions.Item label="Amount Refunded Games">
        <Currency
          currency="cents-to-dollar"
          prefix="$"
          value={statsData.amountRefundedGames}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Number of Refunded Games">
        {statsData.numberRefundedGames}
      </Descriptions.Item>
      <Descriptions.Item label="Dollar Amount Refunded Ratio">
        {statsData.totalRefundRatio * 100}%
      </Descriptions.Item>
      <Descriptions.Item label="Number Refunded Ratio">
        {statsData.numberRefundRatio * 100}%
      </Descriptions.Item>
    </Descriptions>
  );
};

export default UserStatistics;
