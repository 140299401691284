import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
/** type imports */
import type { RootState } from "src/app/store";
import { useThemeSwitcher } from "../theme/ThemeContextInterface";
import { Layout } from "antd";

interface PrivateProps {
  children: React.ReactNode;
  redirectTo: string;
}

const RequireAuth: React.FC<PrivateProps> = ({
  children,
  redirectTo,
}: PrivateProps) => {
  const location = useLocation();
  const { user } = useSelector((state: RootState) => state.userState);
  const { currentTheme } = useThemeSwitcher();
  return user ? (
    <Layout className={`${currentTheme}-theme`}>{children}</Layout>
  ) : (
    <Navigate to={redirectTo} state={{ from: location }} />
  );
};

export default RequireAuth;
