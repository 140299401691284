import { collection, onSnapshot, Unsubscribe } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { internalPhoneNumberConverter } from "src/converters";
import { db } from "src/helpers";
import TriumphPage from "src/shared/layout/TriumphPage";
/** type imports */
import { Table, TableColumnsType } from "antd";
import InternalPhoneNumbersForm from "./InternalPhoneNumbersForm";
import { dateFormatter } from "src/shared/config/constants";

const InternalPhoneNumbers: FC = () => {
  const [internalPhoneNumbers, setInternalPhoneNumbers] = useState<{
    [x: string]: InternalPhoneNumber;
  } | null>(null);

  useEffect(() => {
    let unsubInternalPhoneNumbers: Unsubscribe;
    const internalPhoneNumbersRef = collection(
      db,
      "internalPhoneNumbers"
    ).withConverter(internalPhoneNumberConverter);

    unsubInternalPhoneNumbers = onSnapshot(
      internalPhoneNumbersRef,
      (colSnap) => {
        const phoneNumbers: typeof internalPhoneNumbers = {};
        for (const snap of colSnap.docs) {
          const data = snap.data();
          phoneNumbers[data.phoneNumber] = data;
        }
        setInternalPhoneNumbers(phoneNumbers);
      }
    );
    return () => {
      unsubInternalPhoneNumbers?.();
    };
  }, []);

  const columns: TableColumnsType<InternalPhoneNumber> = [
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Org ID",
      dataIndex: "orgId",
      key: "orgId",
    },
    {
      title: "App User UID",
      dataIndex: "appUserUid",
      key: "appUserUid",
    },
    {
      title: "Disable Cashout",
      dataIndex: "disableCashout",
      key: "disableCashout",
      render: (disableCashout: InternalPhoneNumber["disableCashout"]) => (
        <div>{disableCashout.toString()}</div>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (a) => dateFormatter(a),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (a) => dateFormatter(a),
    },
  ];

  return (
    <TriumphPage>
      <InternalPhoneNumbersForm onClose={() => {}} config={null} />
      <Table
        columns={columns}
        dataSource={Object.values(internalPhoneNumbers || {})}
      />
    </TriumphPage>
  );
};

export default InternalPhoneNumbers;
