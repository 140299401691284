import React, { FC, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import {
  Table,
  TableColumnsType,
  Typography,
  Row,
  Col,
  Button,
  Radio,
  Select,
} from "antd";
import {
  onSnapshot,
  query,
  where,
  orderBy,
  QueryDocumentSnapshot,
  startAfter,
  limit,
  endBefore,
  limitToLast,
  QueryConstraint,
  Unsubscribe,
  getDocs,
} from "firebase/firestore";
import csvDownload from "json-to-csv-export";
import { colGroupNames, getCollectionGroup } from "src/collections";
import { isTriumphEmployee } from "src/helpers";
import dayjs from "dayjs";
import { getAppUserDetails } from "src/features/appUsers/AppUserSlice";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import UserBalanceTransactionDetails from "./TransactionModal";
import Currency from "src/shared/components/currency/Currency";
import moment from "moment";
import Pagination from "src/shared/components/pagination/Pagination";
import { PAGE_SIZE_OPTIONS } from "src/shared/config/constants";
import TriumphPage from "src/shared/layout/TriumphPage";
import GameName from "src/shared/components/GameName/gameName";
import UserInfoExpanded from "./userInfo/UserInfoExpanded";
import { SortOrder } from "antd/lib/table/interface";
/** type imports */
import type { TablePaginationConfig } from "antd";
import type { RangeValue } from "rc-picker/lib/interface";

type TrxTableProps = Exclude<BalanceTransaction, TriumphDepositTrx>;

const defaultPageSize = 50;
const paymentFilterOptions = [
  { label: "Deposit", value: "deposit" },
  { label: "Withdrawal", value: "withdrawal" },
  { label: "Withdrawal-redeposit", value: "withdrawal-redeposit" },
  { label: "Offer", value: "offer" },
];
const UserBalanceTransactions: FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userState);
  const { listOfGames } = useAppSelector((state) => state.gameState);

  const [isPaused, setIsPaused] = useState(false);

  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const [selectedRow, setSelectedRow] = useState<BalanceTransaction | null>(
    null
  );

  const [timeSorting, setTimeSorting] = useState<{
    order: SortOrder;
    key: keyof TrxTableProps;
  }>({ order: "descend", key: "createdAt" });
  const [isLoading, setIsLoading] = useState({ v1: false, v2: false });
  const [isExporting, setIsExporting] = useState(false);
  const [tableQuery, setTableQuery] = useState<{
    [x: string]: QueryConstraint;
  }>({});
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const [transactions, setTransactions] = useState<{
    data: {
      [transactionId: string]: Exclude<
        BalanceTransaction & { colVersion: "1" },
        TriumphDepositTrx
      >;
    } | null;
    last: QueryDocumentSnapshot<BalanceTransaction> | null;
    first: QueryDocumentSnapshot<BalanceTransaction> | null;
  }>({ last: null, first: null, data: null });

  const [transactionsV2, setTransactionsV2] = useState<{
    data: {
      [transactionId: string]: BalanceTransactionV2 & { colVersion: "2" };
    } | null;
    last: QueryDocumentSnapshot<BalanceTransactionV2> | null;
    first: QueryDocumentSnapshot<BalanceTransactionV2> | null;
  }>({ last: null, first: null, data: null });

  const [transactionTypeFilter, setTransactionTypeFilter] = useState("All");
  const [paymentTypeFilter, setPaymentTypeFilter] = useState<string[]>([]);

  React.useEffect(() => {
    let unsubTournCol: Unsubscribe | undefined;
    let unsubTournV2Col: Unsubscribe | undefined;
    if (tableQuery && !isPaused) {
      setIsLoading({ v1: true, v2: true });
      const balanceTransactions = getCollectionGroup(
        colGroupNames.balanceTransactions
      );

      const balanceTransactionsV2 = getCollectionGroup(
        colGroupNames.balanceTransactionsV2
      );

      let querywithPaymentType: {
        [x: string]: QueryConstraint;
      } = {
        "page-size": limit(defaultPageSize / 2),
        ...tableQuery,
      };
      if (paymentTypeFilter.length > 0) {
        querywithPaymentType = {
          ...querywithPaymentType,
          payment: where("type", "in", paymentTypeFilter),
        };
      }
      const { nextV2, next, ...rest } = querywithPaymentType;
      const v2TableQuery = { ...rest };

      if (nextV2) {
        v2TableQuery.next = nextV2;
      }
      if (next) {
        rest.next = next;
      }

      const qV2 = query(
        balanceTransactionsV2,
        where("orgId", "==", user?.activeOrgId),
        orderBy("createdAt", "desc"),
        ...Object.values(v2TableQuery)
      );

      const q = query(
        balanceTransactions,
        where("orgId", "==", user?.activeOrgId),
        orderBy("createdAt", "desc"),
        ...Object.values(rest)
      );

      unsubTournCol = onSnapshot(
        q,
        (colSnap) => {
          const defs: (typeof transactions)["data"] = {};
          const userIds = [];
          for (const tourDefSnap of colSnap.docs) {
            const tourDefId = tourDefSnap.id;
            const tourDef = tourDefSnap.data();
            userIds.push(tourDef.appUserUid);
            if (tourDef.type === "triumph-deposit") continue;
            defs[tourDefId] = { ...tourDef, colVersion: "1" };
          }

          setTransactions((curr) => ({
            last: colSnap.docs[colSnap.docs.length - 1] ?? curr.last,
            first: colSnap.docs[0] ?? curr.first,
            data: defs,
          }));

          setIsLoading((curr) => ({ ...curr, v1: false }));
        },
        (error) => console.error(error.message)
      );
      unsubTournV2Col = onSnapshot(
        qV2,
        (colSnap) => {
          const defs: (typeof transactionsV2)["data"] = {};
          const userIds = [];
          for (const tourDefSnap of colSnap.docs) {
            const tourDefId = tourDefSnap.id;
            const tourDef = tourDefSnap.data();
            userIds.push(tourDef.appUserUid);

            defs[tourDefId] = { ...tourDef, colVersion: "2" };
          }

          setTransactionsV2((curr) => ({
            last: colSnap.docs[colSnap.docs.length - 1] ?? curr.last,
            first: colSnap.docs[0] ?? curr.first,
            data: defs,
          }));

          setIsLoading((curr) => ({ ...curr, v2: false }));
        },
        (error) => console.error(error.message)
      );
    }
    return () => {
      unsubTournV2Col?.();
      unsubTournCol?.(); // Unsubscribe when the component unmounts or when the effect re-runs
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableQuery, isPaused]); // Add isPaused to the dependency array

  async function saveCSV(colKeys: string[]) {
    const exportTrxs: object[] = [];
    const { nextV2, ...rest } = tableQuery;
    const balanceTransactions = getCollectionGroup(
      colGroupNames.balanceTransactions
    );

    const balanceTransactionsV2 = getCollectionGroup(
      colGroupNames.balanceTransactionsV2
    );

    const downloadQuery = query(
      balanceTransactions,
      where("orgId", "==", user?.activeOrgId),
      orderBy("createdAt", "desc"),
      ...Object.values(rest)
    );
    const querySnapshot = await getDocs(downloadQuery);
    querySnapshot.forEach((doc) => {
      const balTrx = doc.data();
      const entry = {
        gameId: balTrx.type === "triumph-deposit" ? "null" : balTrx.gameId,
        type: balTrx.type,
        orgAmount: balTrx.orgAmount,
        orgPromoAmount: balTrx.orgPromoAmount,
        triumphAmount: balTrx.triumphAmount,
        amount: balTrx.amount,
        bonusCashAmount: balTrx.bonusCashAmount,
        createdAt: balTrx.createdAt,
        appUserUid: balTrx.appUserUid,
        uid: balTrx.uid,
      };

      exportTrxs.push(entry);
    });

    const downloadQueryV2 = query(
      balanceTransactionsV2,
      where("orgId", "==", user?.activeOrgId),
      orderBy("createdAt", "desc"),
      ...Object.values({ ...rest, next: nextV2 })
    );
    const querySnapshotV2 = await getDocs(downloadQueryV2);
    querySnapshotV2.forEach((doc) => {
      const balTrx = doc.data();
      const entry = {
        gameId: balTrx.type === "triumph-deposit" ? "null" : balTrx.gameId,
        type: balTrx.type,
        amount: balTrx.amount,
        bonusCashAmount: balTrx.bonusCashAmount,
        createdAt: balTrx.createdAt,
        appUserUid: balTrx.appUserUid,
        uid: balTrx.uid,
      };

      exportTrxs.push(entry);
    });

    const data = {
      data: exportTrxs,
      filename: `export_transactions`,
      delimiter: ",",
      headers: colKeys,
    };
    csvDownload(data);
  }

  const gameFilter: { text: string; value: string }[] = Object.values(
    Object.values({
      ...(transactions.data ?? {}),
      ...(transactionsV2.data ?? {}),
    }).reduce<{
      [game: string]: { text: string; value: string };
    }>((aggreg, trx) => {
      const game = "gameId" in trx ? trx.gameId : null;
      if (!game) {
        return aggreg;
      } else if (game in aggreg) {
        return aggreg;
      } else {
        aggreg[game] = {
          text: listOfGames.find((f) => f.id === game)?.name || game,
          value: game,
        };
        return aggreg;
      }
    }, {})
  );

  const tournamentFilter: {
    text: string;
    value: string;
  }[] = Object.values(
    Object.values({
      ...(transactions.data ?? {}),
      ...(transactionsV2.data ?? {}),
    }).reduce<{
      [tournamentId: string]: { text: string; value: string };
    }>((aggreg, trx) => {
      if ("tournamentId" in trx && trx.tournamentId in aggreg) {
        return aggreg;
      } else {
        aggreg["tournamentId" in trx ? trx.tournamentId : "No tournament"] = {
          text: "tournamentId" in trx ? trx.tournamentId : "No tournament",
          value: "tournamentId" in trx ? trx.tournamentId : "No tournament",
        };
        return aggreg;
      }
    }, {})
  );

  const columns: TableColumnsType<TrxTableProps> = [
    {
      title: "Game",
      dataIndex: "gameId",
      key: "gameId",
      render: (value) => <GameName id={value} />,
      filters: gameFilter,
      onFilter: (value, record) =>
        "gameId" in record ? record.gameId === value : false,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },

    {
      title: "Tournament Id",
      dataIndex: "tournamentId",
      key: "tournamentId",
      filters: tournamentFilter,
      onFilter: (value, record) => {
        if ("tournamentId" in record) return record.tournamentId === value;
        return value === "No tournament";
      },
      render: (id, record) => {
        if (id) {
          switch (record.type) {
            case "finish-blitz":
            case "start-blitz":
              return (
                <Link
                  className="ant-typography"
                  to={`/games/${record.gameId}/tournaments/blitz/${record.tournamentId}`}
                >
                  {id}
                </Link>
              );
            case "finish-group-tournament":
            case "start-group-tournament":
            case "start-tournament":
            case "finish-tournament":
            case "reverse-start-group-tournament":
              return (
                <Typography.Link
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedRow(record);
                  }}
                >
                  {id}
                </Typography.Link>
              );
            default:
              return id;
          }
        } else {
          return "No tournament";
        }
      },
    },

    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      sorter: (a, b) => a.amount - b.amount,
      render: (amount: number) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Bonus Cash",
      dataIndex: "bonusCashAmount",
      key: "bonusCashAmount",
      sorter: (a, b) => a.bonusCashAmount - b.bonusCashAmount,
      render: (amount: number) => (
        <Currency value={amount} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Gems",
      dataIndex: "gemAmount",
      key: "gemAmount",
    },
    {
      title: "User",
      dataIndex: "appUserUid",
      key: "appUserUid",
      render: (id: string) => (
        <Row>
          <Col>
            <Typography.Link
              onClick={(e) => {
                if (isTriumphEmployee()) {
                  e.stopPropagation();
                  onSelectUser(id);
                }
              }}
              className={`mr-1 ${!isTriumphEmployee() ? "disabled-link" : ""}`}
            >
              {id}
            </Typography.Link>
          </Col>
        </Row>
      ),
    },
    {
      title: "Balance Transaction ID",
      dataIndex: "uid",
      key: "uid",
      render: (id, record) => {
        if (
          ["offer", "deposit", "withdrawal", "withdrawal-redeposit"].includes(
            record.type
          )
        ) {
          return (
            <Typography.Link
              onClick={(e) => {
                e.stopPropagation();
                setSelectedRow(record);
              }}
            >
              {id}
            </Typography.Link>
          );
        }
        return id;
      },
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (a: number) => dayjs(a).format("M/D/YY h:mm A"),
      defaultSortOrder: "descend",
    },
  ];

  function onSelectUser(id: string) {
    dispatch(getAppUserDetails(id));
    setSelectedUser(id);
  }

  function computeQueries(
    type: "next" | "prev" | "page-size" | "date" | "payment-filter",
    filter?: number | RangeValue<moment.Moment> | string[]
  ) {
    let q: typeof tableQuery = {};
    switch (type) {
      case "date": {
        q = {
          "date-start": where(
            "createdAt",
            ">=",
            typeof filter !== "number" && filter?.[0]?.valueOf()
          ),
          "date-end": where(
            "createdAt",
            "<=",
            typeof filter !== "number" && filter?.[1]?.valueOf()
          ),
        };
        break;
      }
      case "next": {
        q = {
          ...tableQuery,
          next: startAfter(transactions.last),
          nextV2: startAfter(transactionsV2.last),
          "page-size": limit(pageSize / 2),
        };
        break;
      }
      case "prev": {
        q = {
          ...tableQuery,
          next: endBefore(transactions.first),
          nextV2: endBefore(transactionsV2.first),
          "page-size": limitToLast(pageSize / 2 + 1),
        };
        break;
      }
      case "page-size": {
        if (typeof filter === "number") {
          setPageSize(filter);
          q = { ...tableQuery, "page-size": limit(filter / 2) };
        }
        break;
      }
    }
    setTableQuery(q);
  }

  const paginationConfig: TablePaginationConfig = {
    pageSize,
    onChange: (page, size) => setPageSize(size),
    pageSizeOptions: PAGE_SIZE_OPTIONS.map((e) => `${e}`),
    className: `mobile-pagination-small hide-pagination`,
  };

  const dataSource = useMemo(() => {
    const transactionData = Object.entries(transactions.data ?? {}).map(
      ([id, transaction]): TrxTableProps => {
        switch (transaction.type) {
          case "start-blitz":
          case "finish-blitz":
          case "referrer-bonus":
          case "finish-group-tournament":
          case "start-group-tournament":
          case "new-game":
          case "account-creation-deposit":
          case "deposit":
          case "withdrawal":
          case "withdrawal-redeposit": {
            return transaction;
          }
          default: {
            return {
              ...transaction,
              gameId:
                "gameId" in transaction ? transaction.gameId ?? "none" : "none",
            };
          }
        }
      }
    );

    const transactionV2Data = Object.entries(transactionsV2.data ?? {}).map(
      ([id, trx]) => {
        switch (trx.type) {
          case "start-blitz":
            return {
              ...trx,
              transactionId: null,
              tokenAmount: null,
              orgAmount: null,
              orgPromoAmount: null,
              triumphAmount: null,
              version: "2",
              tournamentId: trx.metadata?.tournamentId,
            };
          case "finish-blitz":
            return {
              ...trx,
              transactionId: null,
              entryPrice: null,
              tokenAmount: null,
              orgAmount: null,
              orgPromoAmount: null,
              triumphAmount: null,
              version: "2",
              tournamentId: trx.metadata?.tournamentId,
            };
          case "referrer-bonus":
            return {
              ...trx,
              refereeUid: null,
              tokenAmount: null,
              orgAmount: null,
              orgPromoAmount: null,
              tournamentId: trx.metadata?.tournamentId,
              triumphAmount: null,
            };
          case "finish-group-tournament":
            return {
              ...trx,
              tokenAmount: null,
              orgAmount: null,
              orgPromoAmount: null,
              triumphAmount: null,
              tournamentId: trx.metadata?.tournamentId,
              tournamentDefinitionId: trx.metadata?.tournamentDefinitionId,
            };
          case "start-group-tournament":
            return {
              ...trx,
              tokenAmount: null,
              totalFee: null,
              orgAmount: null,
              orgPromoAmount: null,
              triumphAmount: null,
              tournamentId: trx.metadata?.tournamentId,
              tournamentDefinitionId: trx.metadata?.tournamentDefinitionId,
            };
          case "new-game":
            return {
              ...trx,
              tokenAmount: null,
              orgAmount: null,
              orgPromoAmount: null,
              triumphAmount: null,
              tournamentId: trx.metadata?.tournamentId,
              rewardType: null,
            };
          case "account-creation-deposit":
            return {
              ...trx,
              tokenAmount: null,
              orgAmount: null,
              tournamentId: trx.metadata?.tournamentId,
              orgPromoAmount: null,
              triumphAmount: null,
            };
          case "deposit":
            return {
              ...trx,
              tokenAmount: null,
              orgAmount: null,
              orgPromoAmount: null,
              tournamentId: trx.metadata?.tournamentId,
              triumphAmount: null,
            };
          case "withdrawal":
            return {
              ...trx,
              requestId: null,
              tokenAmount: null,
              orgAmount: null,
              orgPromoAmount: null,
              tournamentId: trx.metadata?.tournamentId,
              triumphAmount: null,
              withdrawalLimitStatus: "waiting",
              updatedAt: null,
            };
          case "withdrawal-redeposit":
            return {
              ...trx,
              requestId: null,
              tokenAmount: null,
              orgAmount: null,
              orgPromoAmount: null,
              tournamentId: trx.metadata?.tournamentId,
              triumphAmount: null,
            };

          default: {
            return {
              ...trx,
              tournamentId: trx.metadata?.tournamentId,
              tournamentDefinitionId: trx.metadata?.tournamentDefinitionId,
            };
          }
        }
      }
    ) as unknown as TrxTableProps[];

    return [...transactionData, ...transactionV2Data];
  }, [transactions.data, transactionsV2.data]);

  const filteredDataSource = useMemo(() => {
    if (transactionTypeFilter === "All")
      return dataSource.sort((a, b) =>
        timeSorting.order === "ascend"
          ? (a[timeSorting.key] as number) - (b[timeSorting.key] as number)
          : (b[timeSorting.key] as number) - (a[timeSorting.key] as number)
      );
    else if (transactionTypeFilter === "Paid") {
      return dataSource
        .filter((f) => {
          if (
            (Number(f.amount === 0) || !Number(f.amount)) &&
            (Number(f.bonusCashAmount) === 0 || !Number(f.bonusCashAmount))
          )
            return false;
          return true;
        })
        .sort((a, b) =>
          timeSorting.order === "ascend"
            ? (a[timeSorting.key] as number) - (b[timeSorting.key] as number)
            : (b[timeSorting.key] as number) - (a[timeSorting.key] as number)
        );
    }
    return dataSource;
  }, [transactionTypeFilter, dataSource, timeSorting]);

  return (
    <TriumphPage>
      <UserBalanceTransactionDetails
        selectedRow={selectedRow}
        onClose={() => setSelectedRow(null)}
      />
      <UserInfoExpanded
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
      />
      <div className="position-relative">
        <div className="position-absolute-select">
          <Button
            onClick={() => {
              setPaymentTypeFilter(paymentFilterOptions.map((e) => e.value));
              computeQueries("payment-filter");
            }}
            type="link"
          >
            Select All
          </Button>
        </div>
      </div>
      <Row className="pd-8" justify="space-between">
        <Col span={12}>
          <Row>
            <Select
              mode="multiple"
              className="normal-select filter-payment"
              allowClear
              style={{ width: "80%" }}
              placeholder="Filter by payment type"
              value={paymentTypeFilter}
              onClear={() => {
                setPaymentTypeFilter([]);
                computeQueries("payment-filter");
              }}
              onChange={setPaymentTypeFilter}
              options={paymentFilterOptions}
            />
            <Button
              shape="circle"
              className="ml-1"
              icon={<SearchOutlined />}
              onClick={async () => {
                computeQueries("payment-filter");
              }}
            ></Button>
          </Row>
        </Col>
        <Col span={10}>
          <Row justify="end">
            <Col className="ml-1 mr-1">
              <Radio.Group
                options={["All", "Paid"]}
                optionType="button"
                buttonStyle="solid"
                onChange={(e) => setTransactionTypeFilter(e.target.value)}
                value={transactionTypeFilter}
              />
            </Col>
            <Col className="ml-1 mr-1">
              <Button
                shape="round"
                onClick={() => setIsPaused(!isPaused)} // Toggle pause state
              >
                {isPaused ? "Resume" : "Pause"} Updates
              </Button>
            </Col>
            <Col className="ml-1 mr-1">
              <Button
                shape="round"
                onClick={async () => {
                  setIsExporting(true);
                  await saveCSV(columns.map((col) => col.key as string));
                  setIsExporting(false);
                }}
              >
                {isExporting ? "Exporting..." : "Export to .csv"}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={isLoading.v1 && isLoading.v2}
        rowKey="uid"
        onChange={(_, _f, sorter: any) => {
          if (
            sorter &&
            sorter.hasOwnProperty("columnKey") &&
            sorter.hasOwnProperty("order") &&
            sorter.columnKey &&
            sorter.order
          ) {
            setTimeSorting({ order: sorter.order, key: sorter.columnKey });
          }
        }}
        dataSource={filteredDataSource}
        pagination={paginationConfig}
        className="mobile-table-small"
      />
      <Pagination
        pageSize={pageSize}
        onNext={() => {
          computeQueries("next");
        }}
        onPrevious={() => {
          computeQueries("prev");
        }}
        onPageSize={(size: number) => {
          setPageSize(size);
          computeQueries("page-size", size);
        }}
      />
    </TriumphPage>
  );
};

export default UserBalanceTransactions;
