import React from "react";
import { Col, Row, Table, TableColumnsType, Typography } from "antd";
import { onSnapshot, collection } from "firebase/firestore";
import { appUsersConverter, appUsersPublicConverter } from "src/converters";
import { db, isTriumphEmployee } from "src/helpers";
import dayjs from "dayjs";
import { getAppUserDetails } from "src/features/appUsers/AppUserSlice";
import { useAppDispatch } from "src/app/hooks";
import { LinkOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Currency from "src/shared/components/currency/Currency";
import TriumphPage from "src/shared/layout/TriumphPage";
import UserInfoExpanded from "../balanceTransactions/userInfo/UserInfoExpanded";
/** type imports */
import type { TablePaginationConfig } from "antd";

const { Link } = Typography;

const BalanceTransactions: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = React.useState<string | null>(null);

  const [users, setUsers] = React.useState<{
    [uid: string]: AppUser & AppUserPublic;
  } | null>(null);
  const [appUsers, setAppUsers] = React.useState<{
    [uid: string]: AppUser;
  } | null>(null);
  const [appUsersPublic, setAppUsersPublic] = React.useState<{
    [uid: string]: AppUserPublic;
  } | null>(null);
  React.useEffect(() => {
    const appUsersColRef = collection(db, "appUsers").withConverter(
      appUsersConverter
    );

    const appUsersPublicColRef = collection(db, "appUsersPublic").withConverter(
      appUsersPublicConverter
    );
    const unsubUsersCol = onSnapshot(appUsersColRef, (colSnap) => {
      const users: typeof appUsers = {};
      for (const snap of colSnap.docs) {
        const uid = snap.id;
        const user = snap.data();
        users[uid] = user;
      }
      setAppUsers(users);
    });
    const unsubUsersPublicCol = onSnapshot(appUsersPublicColRef, (colSnap) => {
      const users: typeof appUsersPublic = {};
      for (const snap of colSnap.docs) {
        const uid = snap.id;
        const user = snap.data();
        users[uid] = user;
      }
      setAppUsersPublic(users);
    });
    return () => {
      unsubUsersCol();
      unsubUsersPublicCol();
    };
  }, []);
  React.useEffect(() => {
    if (appUsers && appUsersPublic) {
      const appUsersLength = Object.keys(appUsers).length;
      const appUsersPublicLength = Object.keys(appUsersPublic).length;
      if (appUsersLength <= appUsersPublicLength) {
        const completeUsers: typeof users = {};
        for (const [uid, appUser] of Object.entries(appUsers)) {
          const appUserPublic = appUsersPublic[uid];
          if (appUserPublic) {
            completeUsers[uid] = {
              ...appUserPublic,
              ...appUser,
            };
          }
        }
        setUsers(completeUsers);
      }
    }
  }, [appUsers, appUsersPublic]);

  const bannedFilter = [
    { text: "Banned", value: true },
    { text: "Not Banned", value: false },
  ];

  const columns: TableColumnsType<AppUser & AppUserPublic> = [
    {
      title: "uid",
      dataIndex: "uid",
      key: "uid",
      fixed: "left",
      width: 300,
      render: (id: AppUser["uid"]) => (
        <Row>
          <Col>
            <Link
              onClick={(e) => {
                e.stopPropagation();
                onSelectUser(id);
              }}
            >
              {id}
            </Link>
          </Col>
          <Col>
            <LinkOutlined onClick={() => navigate(`/users/${id}`)} />
          </Col>
        </Row>
      ),
    },

    {
      title: "username",
      dataIndex: "username",
      key: "username",
      width: 150,
      fixed: "left",
    },

    {
      title: "Banned",
      dataIndex: "banned",
      width: 150,
      key: "banned",
      filters: bannedFilter,
      onFilter: (value, record) =>
        "banned" in record ? record.banned === value : false,
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "Balance",
      dataIndex: "balance",
      width: 150,
      key: "balance",
      sorter: (a, b) => a.balance - b.balance,
      defaultSortOrder: "descend",
      render: (value) => (
        <Currency value={value} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Token Balance",
      dataIndex: "tokenBalance",
      width: 150,
      key: "tokenBalance",
      sorter: (a, b) => a.tokenBalance - b.tokenBalance,
      render: (value) => (
        <Currency value={value} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Withdrawable Balance",
      dataIndex: "withdrawableBalance",
      width: 150,
      key: "withdrawableBalance",
      sorter: (a, b) => a.withdrawableBalance - b.withdrawableBalance,
      render: (value) => (
        <Currency value={value} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Withdrawal Limit",
      dataIndex: "withdrawalLimit",
      width: 150,
      key: "withdrawalLimit",
      sorter: (a, b) => a.withdrawalLimit - b.withdrawalLimit,
      render: (value) => (
        <Currency value={value} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Location Check",
      dataIndex: "disableLocationCheck",
      width: 100,
      key: "disableLocationCheck",
      render: (value) => (value ? "Disabled" : "Enabled"),
    },

    {
      title: "In Supported Location",
      dataIndex: "isInSupportedLocation",
      width: 150,
      key: "isInSupportedLocation",
      render: (value) => (value ? "Yes" : "No"),
    },
    {
      title: "KYC Status",
      dataIndex: "kycStatus",
      width: 100,
      key: "kycStatus",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: 180,
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (a: AppUser["createdAt"]) => dayjs(a).format("M/D/YY h:mm A"),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      width: 180,
      key: "updatedAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (a: AppUser["updatedAt"]) => dayjs(a).format("M/D/YY h:mm A"),
    },
  ];

  if (isTriumphEmployee()) {
    columns.unshift({
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
      fixed: "left",
    });
  }

  const onSelectUser = (id: string) => {
    dispatch(getAppUserDetails(id));
    setSelectedUser(id);
  };

  const pageinationConfig: TablePaginationConfig = {
    defaultPageSize: 200,
    className: "mobile-pagination-medium normal-option-size",
  };

  return (
    <TriumphPage>
      <UserInfoExpanded
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
      />
      <Table
        columns={columns}
        loading={users === null}
        rowKey="id"
        dataSource={users ? Object.values(users) : []}
        pagination={pageinationConfig}
        scroll={{ x: 1300 }}
        className="mobile-table-medium"
      />
    </TriumphPage>
  );
};

export default BalanceTransactions;
