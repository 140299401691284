import { FC } from "react";
import { Typography } from "antd";

type ChangeCurrency = "cents-to-dollar" | "dollars-to-cents";
export const formatCurrency = (
  value: number | undefined | null,
  currency: ChangeCurrency
) => {
  if (value === undefined) return "";
  if (value === null) return "";
  switch (currency) {
    case "cents-to-dollar":
      if (value === 0) return 0;
      const dollars = value / 100;
      return dollars;
    case "dollars-to-cents":
      if (value === 0) return 0;
      return value * 100;
    default:
      return value;
  }
};

const { Text } = Typography;

const Currency: FC<{
  value: number | undefined | null;
  currency: ChangeCurrency;
  prefix: string;
}> = ({ value, prefix, currency }) => {
  if (value === undefined) return <></>;
  if (value === null) return <></>;
  if (value === 0)
    return (
      <Text>{`${prefix}${Number(formatCurrency(value, currency)).toLocaleString(
        "en-US"
      )}`}</Text>
    );
  if (value < 0)
    return (
      <Text type="danger">{`-${prefix}${Number(
        formatCurrency(value * -1, currency)
      ).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`}</Text>
    );
  return (
    <Text type="success">{`+${prefix}${Number(
      formatCurrency(value, currency)
    ).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`}</Text>
  );
};

export default Currency;
