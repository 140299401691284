import {
  Button,
  Col,
  Row,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Typography,
} from "antd";
import {
  endBefore,
  limit,
  limitToLast,
  onSnapshot,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  startAfter,
  Unsubscribe,
  where,
} from "firebase/firestore";
import { FC, useEffect, useMemo, useState } from "react";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { PAGE_SIZE_OPTIONS, dateFormatter } from "src/shared/config/constants";
import TriumphPage from "src/shared/layout/TriumphPage";
import { useAppSelector } from "src/app/hooks";
import { colGroupNames, getCollectionGroup } from "src/collections";
import { useFilter } from "src/app/useFilters";
import { LinkOutlined } from "@ant-design/icons";
import moment from "moment";
import { RangeValue } from "rc-picker/lib/interface";
import Pagination from "src/shared/components/pagination/Pagination";

type TournamentTableProps = Omit<
  AsyncGroupTournamentV3,
  "config" | "participants" | "players" | "winnerUids" | "results"
> & {
  gameName: string;
};

const { Link } = Typography;
const defaultPageSize = 50;

const AsyncGroupTournament: FC = () => {
  const { user } = useAppSelector((state) => state.userState);
  const navigate = useNavigate();
  const { listOfGames } = useAppSelector((state) => state.gameState);
  const [{ filters }, setFilters] = useFilter<TournamentTableProps>(
    "async-tournament-table-filters"
  );

  const [tableQuery, setTableQuery] = useState<{
    [x: string]: QueryConstraint;
  }>({});
  const [isPaused, setIsPaused] = useState(false);

  const [pageSize, setPageSize] = useState(defaultPageSize);

  const [lastVisible, setLastVisible] =
    useState<QueryDocumentSnapshot<AsyncGroupTournamentV3> | null>(null);
  const [firstVisible, setFirstVisible] =
    useState<QueryDocumentSnapshot<AsyncGroupTournamentV3> | null>(null);

  const [tournaments, setTournaments] = useState<{
    [x: string]: AsyncGroupTournamentV3 & { gameName: string };
  }>();
  const orgId = user?.activeOrgId;
  useEffect(() => {
    let unsubTournCol: Unsubscribe | undefined;
    if (orgId && !isPaused) {
      const groupTournamentsColRef = getCollectionGroup(
        colGroupNames.groupTournamentsV3
      );

      const groupTournamentsQuery = query(
        groupTournamentsColRef,
        orderBy("createdAt", "desc"),
        where("orgId", "==", orgId),
        ...Object.values(tableQuery),
        ...(Object.keys(tableQuery).length === 0 ? [limit(pageSize)] : [])
      );
      unsubTournCol = onSnapshot(
        groupTournamentsQuery,
        (colSnap) => {
          const defs: typeof tournaments = {};
          for (const tourDefSnap of colSnap.docs) {
            const tourDefId = tourDefSnap.id;
            const tourDef = tourDefSnap.data();
            defs[tourDefId] = {
              ...tourDef,
              gameName:
                listOfGames.find((f) => f.id === tourDef.gameId)?.name ||
                tourDef.gameId,
            };
          }
          setTournaments(defs);
          setLastVisible(colSnap.docs[colSnap.docs.length - 1] ?? null);
          setFirstVisible(colSnap.docs[0] ?? null);
        },
        (error) => {
          console.error(error.message);
        }
      );
    }
    return () => {
      unsubTournCol?.();
    };
  }, [orgId, listOfGames, tableQuery, pageSize, isPaused]);

  function computeQueries(
    type: "next" | "prev" | "page-size",
    filter?: number | RangeValue<moment.Moment>
  ) {
    let q: typeof tableQuery = {};
    switch (type) {
      case "next": {
        q = {
          ...tableQuery,
          next: startAfter(lastVisible),
          "page-size": limit(pageSize),
        };
        break;
      }
      case "prev": {
        q = {
          ...tableQuery,
          next: endBefore(firstVisible),
          "page-size": limitToLast(pageSize + 1),
        };
        break;
      }
      case "page-size": {
        if (typeof filter === "number") {
          setPageSize(filter);
          q = { ...tableQuery, "page-size": limit(filter) };
        }
        break;
      }
    }
    setTableQuery(q);
    return q;
  }

  const paginationConfig: TablePaginationConfig = {
    pageSize: pageSize,
    onChange: (page, size) => setPageSize(size),
    pageSizeOptions: PAGE_SIZE_OPTIONS.map((e) => `${e}`),
    className: `mobile-pagination-small hide-pagination`,
  };

  const statusFilters: {
    text: string;
    value: AsyncGroupTournamentV3["status"];
  }[] = Object.values(
    Object.values(tournaments ?? {}).reduce<{
      [type: string]: { text: string; value: AsyncGroupTournamentV3["status"] };
    }>((aggreg, currTournament) => {
      if (currTournament.status in aggreg) {
        return aggreg;
      } else {
        aggreg[currTournament.status] = {
          text: currTournament.status,
          value: currTournament.status,
        };
        return aggreg;
      }
    }, {})
  );
  const gameIdFilters: {
    text: string;
    value: AsyncGroupTournamentV3["gameId"];
  }[] = Object.values(
    Object.values(tournaments ?? {}).reduce<{
      [type: string]: { text: string; value: AsyncGroupTournamentV3["gameId"] };
    }>((aggreg, currTournament) => {
      if (currTournament.gameName in aggreg) {
        return aggreg;
      } else {
        aggreg[currTournament.gameName] = {
          text: currTournament.gameName,
          value: currTournament.gameName,
        };
        return aggreg;
      }
    }, {})
  );
  const participantsMaxFilters: {
    text: string;
    value: AsyncGroupTournamentV3["participantsMax"];
  }[] = Object.values(
    Object.values(tournaments ?? {}).reduce<{
      [type: string]: {
        text: string;
        value: AsyncGroupTournamentV3["participantsMax"];
      };
    }>((aggreg, currTournament) => {
      if (currTournament.participantsMax in aggreg) {
        return aggreg;
      } else {
        aggreg[currTournament.participantsMax] = {
          text: `${currTournament.participantsMax}`,
          value: currTournament.participantsMax,
        };
        return aggreg;
      }
    }, {})
  );
  const participantsSizeFilters: {
    text: string;
    value: AsyncGroupTournamentV3["participantsSize"];
  }[] = Object.values(
    Object.values(tournaments ?? {}).reduce<{
      [type: string]: {
        text: string;
        value: AsyncGroupTournamentV3["participantsSize"];
      };
    }>((aggreg, currTournament) => {
      if (currTournament.participantsSize in aggreg) {
        return aggreg;
      } else {
        aggreg[currTournament.participantsSize] = {
          text: `${currTournament.participantsSize}`,
          value: currTournament.participantsSize,
        };
        return aggreg;
      }
    }, {})
  );
  const columns: TableColumnsType<TournamentTableProps> = [
    {
      title: "Game",
      dataIndex: "gameName",
      key: "gameName",
      width: 130,
      filters: gameIdFilters,
      defaultFilteredValue: filters["gameName"],
      onFilter: (value, record) => record.gameName === value,
    },
    {
      title: "Tournament Id",
      dataIndex: "uid",
      key: "uid",
      width: 150,
      render: (id, record) => (
        <>
          <Link
            onClick={() =>
              navigate(`/games/${record.gameId}/tournaments/asyncGroup/${id}`)
            }
          >
            {id}
          </Link>
          <RouterLink
            target="_blank"
            to={`/games/${record.gameId}/tournaments/asyncGroup/${id}`}
          >
            <LinkOutlined />
          </RouterLink>
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      filters: statusFilters,
      defaultFilteredValue: filters["status"],
      onFilter: (value, record) => record.status === value,
    },
    {
      title: "Participants Max",
      dataIndex: "participantsMax",
      key: "participantsMax",
      width: 100,
      defaultFilteredValue: filters["participantsMax"],
      filters: participantsMaxFilters,
      onFilter: (value, record) => record.participantsMax === value,
    },
    {
      title: "Participants Size",
      dataIndex: "participantsSize",
      key: "participantsSize",
      defaultFilteredValue: filters["participantsSize"],
      width: 100,
      filters: participantsSizeFilters,
      onFilter: (value, record) => record.participantsSize === value,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      width: 150,
      key: "createdAt",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (a) => dateFormatter(a),
    },
    {
      title: "Last Matched At",
      dataIndex: "lastMatchedAt",
      width: 150,
      sorter: (a, b) => (a.lastMatchedAt || 0) - (b.lastMatchedAt || 0),
      render: (a) => dateFormatter(a),
      key: "lastMatchedAt",
    },
    {
      title: "Finished At",
      dataIndex: "finishedAt",
      width: 150,
      sorter: (a, b) => (a.finishedAt || 0) - (b.finishedAt || 0),
      render: (a) => dateFormatter(a),
      key: "finishedAt",
    },
  ];
  const dataSource = useMemo(() => {
    return Object.entries(tournaments ?? {}).map(
      ([uid, tournament]): TournamentTableProps => {
        return {
          uid,
          gameId: tournament.gameId,
          gameName: tournament.gameName,
          RNG: tournament.RNG,
          version: tournament.version,
          participantsMax: tournament.participantsMax,
          participantsSize: tournament.participantsSize,
          type: tournament.type,
          status: tournament.status,
          createdAt: tournament.createdAt,
          lastMatchedAt: tournament.lastMatchedAt,
          finishedAt: tournament.finishedAt,
          removed: tournament.removed,
          orgId: tournament.orgId,
          replays: tournament.replays,
          participantConfigs: tournament.participantConfigs,
          updatedAt: tournament.updatedAt,
        };
      }
    );
  }, [tournaments]);

  return (
    <TriumphPage>
      <Row justify="end">
        <Col
          style={{ display: "flex", alignItems: "center" }}
          className="ml-1 mr-1"
        >
          <Button
            shape="round"
            onClick={() => setIsPaused(!isPaused)} // Toggle pause state
          >
            {isPaused ? "Resume" : "Pause"} Updates
          </Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        loading={!tournaments}
        rowKey="uid"
        dataSource={dataSource}
        pagination={paginationConfig}
        scroll={{ x: 1300 }}
        className="mobile-table-large"
        onChange={(pagination, filters, sorter) => {
          setFilters({ pagination, filters, sorter });
        }}
      />
      <Pagination
        pageSize={pageSize}
        onNext={() => {
          computeQueries("next");
        }}
        onPrevious={() => {
          computeQueries("prev");
        }}
        onPageSize={(size: number) => {
          setPageSize(size);
          computeQueries("page-size", size);
        }}
      />
    </TriumphPage>
  );
};
export default AsyncGroupTournament;
