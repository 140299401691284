import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { intermediateScoreConverter } from "src/converters";
import { db, generateFirestorePath, getRandomColor } from "src/helpers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  Legend,
  TooltipItem,
} from "chart.js";
import "chart.js/auto";
import { Line } from "react-chartjs-2";
import { Descriptions, Divider, Empty, Typography } from "antd";

import { useAppSelector } from "src/app/hooks";
import { appUserPublicSelect } from "src/features/appUsersPublic/appUserPublicSlice";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
);

const STEP_SIZE = 3;
const getOptions = (maxTicksLimit: number) => ({
  responsive: true,
  plugins: {
    title: {
      display: true,
      text: "Intermediate Scores",
    },
    tooltip: {
      callbacks: {
        footer: (items: TooltipItem<"line">[]) =>
          `Sequence ID: ${items
            .map(
              (item) =>
                `(${item.dataset?.label}=${
                  (item.raw as { sequenceId: number }).sequenceId
                })`
            )
            .join(", ")}`,
      },
    },
  },
  interaction: {
    intersect: false,
  },
  parsing: {
    xAxisKey: "time",
    yAxisKey: "score",
  },
  // scaleShowValues: true,
  scales: {
    xAxis: {
      display: true,
      title: {
        display: true,
        text: "Time Elapsed (seconds)",
      },
      ticks: {
        autoSkip: true,
        source: "data",
        maxTicksLimit,
        stepSize: STEP_SIZE,
      },
    },
    yAxis: {
      display: true,
      title: {
        display: true,
        text: "Score",
      },
    },
  },
});

interface Props {
  tournamentId: string;
  gameId: string;
  orgId: string;
}

const IntermediateScoreGraph: FC<Props> = ({ tournamentId, gameId, orgId }) => {
  const [fraudDetectionMeta, setFraudDetectionMeta] =
    useState<Record<"metadata", { maxScoreNumberIncreasePerSecond: number }>>();
  const [intermediateScore, setIntermediateScore] = useState<{
    [x: string]: Array<IntermediateScore>;
  }>({});

  const { usersPublic } = useAppSelector(appUserPublicSelect);
  const params = useLocation();

  const getMetaData = useCallback(async () => {
    const paramsMaxScore = Number(
      new URLSearchParams(params.search).get(
        "maxScoreNumberIncreasePerSecond"
      ) || undefined
    );
    if (!isNaN(paramsMaxScore)) {
      setFraudDetectionMeta({
        metadata: { maxScoreNumberIncreasePerSecond: paramsMaxScore },
      });
      return;
    }

    const gamesRef = doc(
      collection(db, generateFirestorePath("games")),
      gameId
    );

    const gameFeatureRef = collection(gamesRef, "features");
    const gameFraudDetectionRef = doc(gameFeatureRef, "fraudDetection");
    const gameFraudMeta = (
      await getDoc(gameFraudDetectionRef)
    ).data() as typeof fraudDetectionMeta;

    if (!gameFraudMeta?.metadata?.maxScoreNumberIncreasePerSecond) {
      const configRef = collection(db, "appConfig");
      const globalRef = doc(configRef, "global");
      const featureRef = collection(globalRef, "features");
      const fraudDetectionRef = doc(featureRef, "fraudDetection");
      const data = (
        await getDoc(fraudDetectionRef)
      ).data() as typeof fraudDetectionMeta;
      setFraudDetectionMeta(data);
    } else {
      setFraudDetectionMeta(gameFraudMeta);
    }
  }, [gameId, params]);

  useEffect(() => {
    const gamesRef = doc(
      collection(db, generateFirestorePath("games")),
      gameId
    );

    getMetaData();
    const intermediateColRef = collection(
      gamesRef,
      "intermediateScores"
    ).withConverter(intermediateScoreConverter);

    const intermediateQueryRef = query(
      intermediateColRef,
      where("gameId", "==", gameId),
      where("tournamentId", "==", tournamentId)
    );

    const unsubSnapshot = onSnapshot(intermediateQueryRef, (scoreSnap) => {
      const scoreDef: typeof intermediateScore = {};
      for (const scoreDefSnap of scoreSnap.docs) {
        const data = scoreDefSnap.data();
        const prev = scoreDef[data.appUserUid];

        scoreDef[data.appUserUid] = [...(prev || []), data];
      }

      setIntermediateScore(scoreDef);
    });

    return () => {
      unsubSnapshot();
    };
  }, [gameId, tournamentId, orgId, getMetaData]);

  const fraudDetectionTriggered = useMemo(() => {
    if (
      !fraudDetectionMeta?.metadata?.maxScoreNumberIncreasePerSecond ||
      Object.keys(intermediateScore).length === 0
    )
      return;
    return Object.entries(intermediateScore).map(([key, scores]) => {
      // Use a Map to ensure each sequenceId is unique, retaining the last occurrence
      const scoresMap = scores.reduce((map, score) => {
        map.set(score.sequenceId, score);
        return map;
      }, new Map());

      // Convert the map to an array of scores, then sort by createdAt
      const uniqueScores = Array.from<IntermediateScore>(
        scoresMap.values()
      ).sort((a, b) => a.createdAt - b.createdAt);

      const t1 = uniqueScores[0].createdAt;

      for (let i = 1; i < uniqueScores.length; i++) {
        const prevScore = uniqueScores[i - 1];
        const currScore = uniqueScores[i];
        const timeDiffInSeconds =
          (currScore.createdAt - prevScore.createdAt) / 1000;
        const valueDiff = currScore.value - prevScore.value;
        const rateOfChange = Math.abs(valueDiff / timeDiffInSeconds); // Use absolute rate of change

        if (
          rateOfChange >
          fraudDetectionMeta.metadata.maxScoreNumberIncreasePerSecond
        ) {
          return {
            userId: key,
            currScore,
            prevScore,
            rateOfChange,
            timeDiffInSeconds,
            valueDiff,
            t1,
          };
          // Early exit if any rate of change exceeds the threshold
        }
      }
      return false;
    });
  }, [intermediateScore, fraudDetectionMeta]);

  const dataSet = useMemo(() => {
    const seconds = Object.values(intermediateScore).reduce(
      (prev: number[], curr) => {
        const t1 = curr.sort((a, b) => a.createdAt - b.createdAt)[0].createdAt;
        const d = curr.map((e) => dayjs(e.createdAt).diff(t1, "s"));
        return [...prev, ...d];
      },
      []
    );

    const slicedLabels = Array.from(new Set(seconds))
      .sort((a, b) => a - b)
      .map((e) => `${e}s`);

    return {
      maxTicksLimit: Math.ceil(slicedLabels.length / STEP_SIZE),
      graphData: {
        labels: slicedLabels,
        datasets: Object.entries(intermediateScore).map(([userId, value]) => {
          const sortedValue = value.sort((a, b) => a.createdAt - b.createdAt);
          const valueT1 = sortedValue[0].createdAt;
          return {
            data: sortedValue.map((e) => ({
              score: e.value,
              time: dayjs(e.createdAt).diff(valueT1, "s") + "s",
              sequenceId: e.sequenceId,
            })),
            borderColor: getRandomColor(),
            backgroundColor: getRandomColor(),
            fill: false,
            tension: 0.4,
            label: usersPublic[userId]?.username,
          };
        }),
      },
    };
  }, [intermediateScore, usersPublic]);

  const options = getOptions(dataSet.maxTicksLimit);
  if (dataSet.graphData.datasets.length === 0)
    return (
      <>
        <Typography.Title level={4}>Intermediate Score</Typography.Title>
        <Empty className="mb-2" />
      </>
    );
  return (
    <>
      <Divider />
      <Line options={options} data={dataSet.graphData} />
      {fraudDetectionTriggered &&
        fraudDetectionTriggered.length > 0 &&
        fraudDetectionTriggered.map(
          (e) =>
            e && (
              <>
                <Divider />

                <Descriptions
                  title={`Fraud detected for ${usersPublic[e.userId].username}`}
                  layout="horizontal"
                  colon
                  column={1}
                  bordered
                  className="pb-2"
                >
                  <Descriptions.Item label="Max Score Number Increase Per Second">
                    {
                      fraudDetectionMeta?.metadata
                        ?.maxScoreNumberIncreasePerSecond
                    }
                  </Descriptions.Item>

                  <Descriptions.Item label="Rate of Change">
                    {e.rateOfChange}
                  </Descriptions.Item>
                  <Descriptions.Item label="T2 Sequence ID">
                    {e.currScore.sequenceId}
                  </Descriptions.Item>
                  <Descriptions.Item label="T1 Sequence ID">
                    {e.prevScore.sequenceId}
                  </Descriptions.Item>
                  <Descriptions.Item label="T2 Time">
                    {dayjs(e.currScore.createdAt).diff(e.t1, "s") + "s"}
                  </Descriptions.Item>
                  <Descriptions.Item label="T1 Time">
                    {dayjs(e.prevScore.createdAt).diff(e.t1, "s") + "s"}
                  </Descriptions.Item>

                  <Descriptions.Item label="Time Diff In Seconds">
                    {e.timeDiffInSeconds}
                  </Descriptions.Item>
                  <Descriptions.Item label="Difference between score">
                    {e.valueDiff}
                  </Descriptions.Item>
                </Descriptions>
              </>
            )
        )}
      <Divider />
    </>
  );
};

export default IntermediateScoreGraph;
