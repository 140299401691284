import { Modal, Tag } from "antd";
import { FC, useMemo, useState } from "react";
import { PlayCircleOutlined } from "@ant-design/icons";
import { getCdnUrl } from "src/helpers";

interface Props {
  username: string;
  replay: UserReplay | null;
}
const ReplayVideo: FC<Props> = ({ replay, username }) => {
  const [open, setOpen] = useState(false);

  const status = useMemo(() => {
    if (!replay) return <Tag color="yellow">None</Tag>;
    switch (replay.status) {
      case "corrupted":
        return <Tag color="red">Corrupted</Tag>;
      case "normal":
        return <PlayCircleOutlined onClick={() => setOpen(true)} />;
      default:
        return <Tag color="yellow">None</Tag>;
    }
  }, [replay]);
  return (
    <>
      {status}
      <Modal
        title={`${username}'s replay`}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        destroyOnClose
        bodyStyle={{ height: "85vh" }}
        footer={false}
      >
        {open && replay && replay.status === "normal" && (
          <video width="100%" height="100%" controls autoPlay={true}>
            <source
              src={`${getCdnUrl()}${replay.video.path}`}
              type="video/mp4"
            />
          </video>
        )}
      </Modal>
    </>
  );
};

export default ReplayVideo;
