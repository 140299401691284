import { Avatar, Menu, MenuProps, message, Switch } from "antd";
import { signOut } from "firebase/auth";
import { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { clearUser } from "src/features/authentication/userSlice";
import { auth, isTriumphEmployee } from "src/helpers";
import { CopyOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import { useThemeSwitcher } from "../theme/ThemeContextInterface";
import { clearLocalStorage } from "src/shared/config/constants";

const SettingsMenu: FC = () => {
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const { user } = useAppSelector((state) => state.userState);
  const isTriumphAdmin = isTriumphEmployee();
  const { switcher, currentTheme } = useThemeSwitcher();
  const [isDarkMode, setIsDarkMode] = useState(currentTheme === "dark");

  // menu item key names
  // const settingsKey = "settings";
  const signOutKey = "sign-out";
  const tokenKey = "firebase-token";

  function copyMsg() {
    message.success("Copied Token");
  }
  function copyFailMsg() {
    message.error("Copy Failed");
  }

  // SETTINGS MENU PROPS
  async function handleSettingsSelection(key: string) {
    try {
      if (key === tokenKey && isTriumphAdmin) {
        const token = await auth.currentUser?.getIdToken(true);
        if (token) {
          await navigator.clipboard.writeText(token);
          copyMsg();
        } else {
          copyFailMsg();
          console.error("token was not copied");
        }
      } else if (key === signOutKey) {
        await signOut(auth);
        dispatch(clearUser());
        clearLocalStorage();
        // }
        // else if (key === settingsKey) {
        //   navigate("/settings");
      } else {
        return;
      }
    } catch (error) {
      console.error(error);
    }
  }

  const settingsMenuItems: MenuProps["items"] = [
    {
      key: "Theme",
      label: (
        <Switch
          checkedChildren={<FontAwesomeIcon icon={faMoon} />}
          unCheckedChildren={<FontAwesomeIcon icon={faSun} />}
          checked={isDarkMode}
          onChange={(checked, e) => {
            switcher({ theme: checked ? "dark" : "light" });
            setIsDarkMode(checked);
          }}
        />
      ),
    },
    {
      key: "SubMenu",
      label: (
        <Avatar
          shape={"circle"}
          size={42}
          style={{ border: "2px solid #5A5A5A" }}
          src={
            user && user.photoURL ? (
              <img
                src={user.photoURL}
                alt={user.displayName?.charAt(0) ?? ""}
                referrerPolicy="no-referrer"
              />
            ) : null
          }
        >
          <UserOutlined style={{ fontSize: 24 }} />
        </Avatar>
      ),
      children: [
        // {
        //   label: "Settings",
        //   key: settingsKey,
        //   icon: <SettingOutlined />,
        // },
        {
          label: "Copy Firebase Id Token",
          key: tokenKey,
          icon: <CopyOutlined />,
          className: !isTriumphAdmin ? "d-none" : "",
        },
        {
          label: "Sign Out",
          key: signOutKey,
          icon: <LogoutOutlined />,
        },
      ],
    },
  ];

  return (
    <Menu
      disabledOverflow={true}
      className="right-menu text-disable-button"
      mode="horizontal"
      selectable={false}
      items={settingsMenuItems}
      onClick={async (e) => await handleSettingsSelection(e.key)}
    />
  );
};

export default SettingsMenu;
