import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDocs, query, where, documentId } from "firebase/firestore";
/** type imports */
import type { RootState } from "src/app/store";
import { colNames, getCollection } from "src/collections";
import { isTriumphEmployee } from "src/helpers";

type OrgState = {
  loading: boolean;
  orgs: { [orgId: string]: Organization };
};

const initialState: OrgState = {
  loading: false,
  orgs: {},
};

export const getOrganizations = createAsyncThunk<
  OrgState["orgs"],
  void,
  { state: RootState }
>("organizations/getOrg", async (payload, { getState }) => {
  try {
    const { user } = getState().userState;

    if (!user) {
      return {};
    }

    let adminUserOrgsQuery;

    if (isTriumphEmployee()) {
      adminUserOrgsQuery = query(getCollection(colNames.organizations));
    } else {
      adminUserOrgsQuery = query(
        getCollection(colNames.organizations),
        where(documentId(), "in", user.orgIds)
      );
    }

    const orgsSnap = await getDocs(adminUserOrgsQuery);
    const orgs: { [orgId: string]: Organization } = {};
    for (const org of orgsSnap.docs) {
      orgs[org.id] = org.data();
    }
    return orgs;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const orgSlice = createSlice({
  name: "organizations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganizations.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getOrganizations.fulfilled, (state, action) => {
      state.loading = false;
      state.orgs = action.payload;
    });

    builder.addCase(getOrganizations.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const orgSelect = (state: RootState) => state.orgState;

export default orgSlice.reducer;
