import React, { useState } from "react";
import { Input } from "antd";
import "./index.css";

/* type imports */
import { SizeType } from "antd/lib/config-provider/SizeContext";

// input props
interface FloatLabelProps {
  name?: string;
  label?: string;
  size?: SizeType;
  value?: string | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  type?: string;
  isLabel?: boolean;
  required?: boolean;
}

const FloatInput = (props: FloatLabelProps) => {
  const [focus, setFocus] = useState(false);
  const { name, label, value, type, isLabel, required, size, onChange } = props;
  let { placeholder } = props;
  if (!placeholder) {
    placeholder = label;
  }

  const isOccupied = focus || (value && value.toString().length !== 0);

  const labelClass =
    isOccupied || isLabel ? "label as-label" : "label as-placeholder";

  const requiredMark = required ? <span className="text-danger">*</span> : null;
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input
        disabled={isLabel ?? false}
        onChange={onChange}
        type={type}
        value={value}
        name={name}
        size={size}
      />

      <label style={{ backgroundColor: "#14141" }} className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  );
};

export default FloatInput;
