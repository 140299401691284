import { Button, Col, Row, Select } from "antd";
import { FC, useState } from "react";
import { PAGE_SIZE_OPTIONS } from "src/shared/config/constants";

const { Option } = Select;
const Pagination: FC<{
  pageSize?: number;
  pageSizeOptions?: Array<number>;
  disableNext?: boolean;
  onNext: () => void;
  onPrevious: () => void;
  onPageSize: (pageSize: number) => void;
  loading?: boolean;
}> = ({
  pageSize = 50,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  onNext,
  onPrevious,
  onPageSize,
  loading,
  disableNext,
}) => {
  const [selectedPageSize, setSelectedPageSize] = useState(pageSize);
  const [currentPage, setCurretPage] = useState(1);
  const handleChange = (e: number) => {
    setSelectedPageSize(e);
    onPageSize(e);
  };

  return (
    <>
      <Row justify="end">
        <Row gutter={[4, 4]}>
          <Col>
            <Button
              disabled={currentPage < 2 || loading}
              onClick={() => {
                if (currentPage === 1) return;
                onPrevious();
                setCurretPage(currentPage - 1);
              }}
            >
              Previous
            </Button>
          </Col>
          <Col>
            <Button
              disabled={loading || disableNext}
              onClick={() => {
                onNext();
                setCurretPage(currentPage + 1);
              }}
            >
              Next
            </Button>
          </Col>
          <Col>
            <Select
              defaultValue={pageSize}
              style={{ width: 120 }}
              onChange={handleChange}
              value={selectedPageSize}
              className="select-normal"
              disabled={loading}
            >
              {pageSizeOptions.map((e) => (
                <Option key={e} value={e}>
                  {e} / page
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default Pagination;
