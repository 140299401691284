import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  doc,
  documentId,
  getDocs,
  query,
  runTransaction,
  where,
} from "firebase/firestore";
import { colNames, getCollection } from "src/collections";
import { db } from "src/helpers";
/* type imports */
import type { RootState } from "src/app/store";

type AppUserState = {
  loading:
    | "idle"
    | "fetching"
    | "saving"
    | "finish"
    | "savingUserBalance"
    | "savingToken";
  users: Array<AppUser & AppUserPublic>;
  errors: { [x: string]: string | undefined };
};

const initialState = {
  appUsers: {
    loading: "idle",
    users: [],
    usersPublic: {},
    errors: {},
  },
};

export const getAppUserDetails = createAsyncThunk(
  "appUser/getAppUserDetails",
  async (id?: string) => {
    try {
      const userColRef = getCollection(colNames.appUsers);
      const publicUserColRef = getCollection(colNames.appUsersPublic);

      const privateUserQuery = query(userColRef, where(documentId(), "==", id));
      const publicUserQuery = query(
        publicUserColRef,
        where(documentId(), "==", id)
      );
      const [privateUserSnapshot, publicUserSnapshot] = await Promise.all([
        getDocs(privateUserQuery),
        getDocs(publicUserQuery),
      ]);

      const data = {
        ...privateUserSnapshot.docs[0].data(),
        ...publicUserSnapshot.docs[0].data(),
      };
      return data;
    } catch (error) {
      throw error;
    }
  },
  {
    condition: (
      id,
      { getState, extra }: { getState: () => RootState; extra: any }
    ) => {
      if (!id) return false;
      const { appUserState } = getState();
      const existing = appUserState.users.find((f) => f.uid === id);
      if (existing) return false;
      return true;
    },
  }
);

export const getAllAppUserDetails = createAsyncThunk(
  "appUser/getAllAppUserDetails",
  async () => {
    try {
      const userColRef = getCollection(colNames.appUsers);
      const publicUserColRef = getCollection(colNames.appUsersPublic);

      const [privateUserSnapshot, publicUserSnapshot] = await Promise.all([
        getDocs(userColRef),
        getDocs(publicUserColRef),
      ]);

      let data: { [id: string]: AppUser } = {};
      let publicData: { [id: string]: AppUserPublic & AppUser } = {};
      privateUserSnapshot.forEach((result) => {
        if (result.exists()) {
          data[result.id] = result.data();
        }
      });
      publicUserSnapshot.forEach((result) => {
        if (result.exists()) {
          publicData[result.id] = {
            ...{ uid: result.id },
            ...data[result.id],
            ...result.data(),
          };
        }
      });

      return Object.values(publicData);
    } catch (error) {
      throw error;
    }
  }
);

export const setUserBan = createAsyncThunk(
  "appUser/setUserBan",
  async (payload: { uid: string }) => {
    const userColRef = getCollection(colNames.appUsers);
    const userdoc = doc(userColRef, payload.uid);

    await runTransaction(db, async (transaction) => {
      const appUserSnapshot = await transaction.get(userdoc);
      if (!appUserSnapshot.exists()) {
        throw new Error("User doesn't exists");
      }
      const appUserData = appUserSnapshot.data();
      const { banned } = appUserData;
      transaction.update(userdoc, {
        banned: !banned,
      });
    });
  }
);

const appUserSlice = createSlice({
  name: "appUser",
  reducers: {},
  initialState: initialState.appUsers as AppUserState,
  extraReducers: (builder) => {
    builder.addCase(getAppUserDetails.pending, (state) => {
      state.loading = "fetching";
    });
    builder.addCase(getAppUserDetails.fulfilled, (state, action) => {
      state.loading = "finish";
      state.users.push(action.payload);
    });
    builder.addCase(getAppUserDetails.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(getAllAppUserDetails.pending, (state) => {
      state.loading = "fetching";
    });
    builder.addCase(getAllAppUserDetails.fulfilled, (state, action) => {
      state.loading = "finish";
      state.users = action.payload;
    });
    builder.addCase(getAllAppUserDetails.rejected, (state) => {
      state.loading = "idle";
    });
    builder.addCase(setUserBan.pending, (state, action) => {
      state.loading = "saving";
    });
    builder.addCase(setUserBan.fulfilled, (state, action) => {
      const appUser = state.users.find((f) => f.uid === action.meta.arg.uid);
      if (appUser) {
        appUser.banned = !appUser.banned;
        state.loading = "finish";
        state.errors[action.meta.arg.uid] = undefined;
      }
    });
    builder.addCase(setUserBan.rejected, (state, action) => {
      state.loading = "idle";
      state.errors[action.meta.arg.uid] = action.error.message;
    });
  },
});

export const appUserSelect = (state: RootState) => state.appUserState;

export default appUserSlice.reducer;
