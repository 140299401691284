import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "@firebase/firestore";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import axios from "axios";
import { getStorage } from "firebase/storage";
import { ZodType, ZodTypeDef } from "zod";
/** type imports */
import type { FirebaseOptions } from "firebase/app";

export function generateFirestorePath(collectionName: string): string {
  const nodeEnv = process.env.NODE_ENV;
  if (nodeEnv === "production") {
    return collectionName;
  }
  return collectionName;
}

let firebaseConfig: FirebaseOptions | undefined;
let appCheckSitKey: string | undefined;
export const environment = process.env.REACT_APP_ENVIRONMENT;

if (environment === "develop") {
  firebaseConfig = {
    apiKey: "AIzaSyBeXrX7SjAC2Y7hjthIVq9QrK71hnHeQUo",
    authDomain: "triumph-dcdb2.firebaseapp.com",
    databaseURL: "https://triumph-dcdb2-default-rtdb.firebaseio.com",
    projectId: "triumph-dcdb2",
    storageBucket: "triumph-dcdb2.appspot.com",
    messagingSenderId: "405629688148",
    appId: "1:405629688148:web:dfc59930515f88bdc310c1",
    measurementId: "G-8P14VWMBHY",
  };
  appCheckSitKey = "6LeZOJceAAAAAI2vBOxEjvYpX1VyFLme6Wb5IYKq";
} else if (environment === "prod") {
  firebaseConfig = {
    apiKey: "AIzaSyCQD461vIA94ZKkvjqIZFC5A3iXTc2wS-s",
    authDomain: "triumph-prod.firebaseapp.com",
    databaseURL: "https://triumph-prod-default-rtdb.firebaseio.com",
    projectId: "triumph-prod",
    storageBucket: "triumph-prod.appspot.com",
    messagingSenderId: "801438012284",
    appId: "1:801438012284:web:ae45279730dbca61f7caf9",
    measurementId: "G-3BZQ5YHT6G",
  };
  appCheckSitKey = "6LeYP5ceAAAAAP8o0AELPtbD2iNh-B-0SvKyJXDl";
} else {
  firebaseConfig = {
    apiKey: "AIzaSyADu70We643r4qDnwjYK6k0dBNuSdRgPG0",
    authDomain: "triumph-debug.firebaseapp.com",
    databaseURL: "https://triumph-debug-default-rtdb.firebaseio.com",
    projectId: "triumph-debug",
    storageBucket: "triumph-debug.appspot.com",
    messagingSenderId: "967969074570",
    appId: "1:967969074570:web:cfee90a676369b856a6c73",
    measurementId: "G-CRF1Z1DTQK",
  };
}

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

if (appCheckSitKey) {
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(appCheckSitKey),

    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true,
  });
}

function generateBackendUrl() {
  if (environment === "debug" || environment === undefined) {
    if (process.env.NODE_ENV === "production") {
      return "https://debug-api.triumpharcade.com";
    }
    return "http://localhost:8080";
  } else if (environment === "develop") {
    return "https://dev-api.triumpharcade.com";
  } else if (environment === "prod") {
    return "https://api.triumpharcade.com";
  } else {
    throw new Error("unknown environment");
  }
}

function generateBackendUrlV2() {
  if (environment === "debug" || environment === undefined) {
    if (process.env.NODE_ENV === "production") {
      return "https://go-triumph-backend-nql7fu4yxq-uc.a.run.app";
    }
    return "http://localhost:8080";
  } else if (environment === "prod") {
    return "https://go-triumph-backend-na5us6vvjq-uc.a.run.app";
  } else {
    throw new Error("unknown environment");
  }
}

export function getCdnUrl() {
  if (environment === "debug" || environment === undefined) {
    return "https://debug-cdn.triumpharcade.com/";
  } else if (environment === "develop") {
    return "https://dev-cdn.triumpharcade.com/";
  } else if (environment === "prod") {
    return "https://cdn.triumpharcade.com/";
  } else {
    throw new Error("unknown environment");
  }
}

export function getCdnPhoto(photoPath: AppUserPublic["profilePhotoPath"]) {
  if (photoPath) return getCdnUrl() + photoPath;
  return "/fallback_img.png";
}

export function getPhoto(user: (AppUser & AppUserPublic) | AppUserPublic) {
  if (user.profilePhotoPath) {
    return getCdnPhoto(user.profilePhotoPath);
  }
  if (user.profilePhotoURL) return user.profilePhotoURL;
  return "/fallback_img.png";
}

export const firebaseIdTokenKey = "Firebase-Id-Token";
/** create axios instance for backend */
const axiosInstanceTemp = axios.create({
  baseURL: generateBackendUrl(),
});
const axiosInstanceTempV2 = axios.create({
  baseURL: generateBackendUrlV2(),
});

axiosInstanceTemp.interceptors.request.use(async (config) => {
  const appUser = auth.currentUser;

  if (!appUser || !config.headers) {
    throw new Error("Unauthenticated");
  }
  const firebaseIdToken = await appUser.getIdToken();
  /** add firebase token to each request */
  config.headers[firebaseIdTokenKey] = firebaseIdToken;
  return config;
});

axiosInstanceTempV2.interceptors.request.use(async (config) => {
  const appUser = auth.currentUser;

  if (!appUser || !config.headers) {
    throw new Error("Unauthenticated");
  }
  const firebaseIdToken = await appUser.getIdToken();
  /** add firebase token to each request */
  config.headers[firebaseIdTokenKey] = firebaseIdToken;
  return config;
});

export const axiosInstance = axiosInstanceTemp;
export const axiosInstanceV2 = axiosInstanceTempV2;

export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app);
export const db = getFirestore(app);

export function Logger(file: string, func: string, message: string | unknown) {
  if (typeof message === "string") {
    console.error(file, func, message);
  } else if (message instanceof Error) {
    console.error(file, func, message.message);
  }
}

// Performs type validation at runtime according to the defined schemas in
// validation.ts
export function validateType<Type>(
  target: any,
  schema: ZodType<Type, ZodTypeDef, Type>
): asserts target is Type {
  // if parse doesn't throw a ZodError, then type will be asserted
  schema.parse(target);
}

export const fixDigits = (x: number, len: number) => {
  const precised = x.toPrecision(len);

  if (precised.length === len + 1) return precised;
  if (precised.length < len + 1) return precised;
  return precised.slice(0, len + 1);
};

export function isTriumphEmployee() {
  const { currentUser } = auth;
  if (window.location.hostname === "localhost") return true;

  if (!currentUser) {
    return false;
  }
  const { email } = currentUser;
  if (!email) {
    return false;
  }
  const matches = email.match(/.*@triumpharcade[.]com$/);
  if (matches === null) {
    return false;
  }
  return true;
}

const { dwolla: dwollaInstance } = window;

if (environment === "prod") {
  dwollaInstance.configure("production");
} else {
  dwollaInstance.configure("sandbox");
}

export function getRandomColor() {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
}

export function calculateOrgAmounts(
  org: Pick<Organization, "uid" | "promoBalance" | "balance">,
  transferAmount: number | null = null
): {
  amount: number;
  promoAmount: number;
  triumphAmount: number;
  externalAmount: number;
} {
  const { balance, promoBalance, uid } = org;
  if (balance < 0) {
    throw new Error(`org ${uid} has a negative balance`);
  }
  let externalAmount = transferAmount ?? balance;
  if (externalAmount < 0) {
    throw new Error(
      `Withdrawal amount ${externalAmount} cannot be less that 0`
    );
  }

  if (externalAmount > balance) {
    throw new Error(
      `Withdrawal amount ${externalAmount} cannot be greater than the balance`
    );
  }

  if (promoBalance >= 0) {
    if (externalAmount > balance) {
      throw new Error(
        `Unable to withdraw: ${externalAmount} from total org balance: ${balance}`
      );
    }
    return {
      amount: -1 * externalAmount,
      promoAmount: 0,
      externalAmount,
      triumphAmount: 0,
    };
  }

  /**
   * This contains the amount that could be transfered if:
   * `leftOverAmount >= 0`
   */
  const leftOverAmount = externalAmount + promoBalance;

  if (leftOverAmount >= 0) {
    /**
     * Since leftOverAmount is positive we
     * can zero out the promoBalance
     */
    const promoAmount = -1 * promoBalance;
    if (externalAmount > balance) {
      throw new Error(`org ${uid} has insufficient funds`);
    }

    /**
     * ultimately the amount that is being requested must come from
     * the orgs balance we store the external amount in `amount`
     * so we can debit their cash account
     */
    const amount = -1 * externalAmount;
    /**
     * since leftOverAmount is positive we
     * assign this to `externalAmount` as the amount
     * that will be used to credit an external acount
     */
    externalAmount = leftOverAmount;
    return {
      amount,
      promoAmount,
      externalAmount,
      triumphAmount: 0,
    };
  }
  /**
   * since we do not have enought left over
   * to fund a external credit we will transfer
   * the amount to promoBalance from balance
   */
  const promoAmount = externalAmount;
  const amount = -1 * externalAmount;
  externalAmount = 0;
  const triumphAmount = 0;
  return {
    amount,
    promoAmount,
    externalAmount,
    triumphAmount,
  };
}

export const dwollaBaseUrl = dwollaInstance.environment();
export const dwolla = dwollaInstance;
