import {
  Avatar,
  Button,
  Card,
  Col,
  Dropdown,
  List,
  Menu,
  MenuProps,
  message,
  Image,
  Row,
  Space,
  Spin,
  Typography,
  Radio,
  RadioChangeEvent,
  Modal,
  Form,
} from "antd";
import {
  DownOutlined,
  KeyOutlined,
  SettingOutlined,
  UserDeleteOutlined,
} from "@ant-design/icons";
import FloatInput from "src/shared/components/base/FloatInput";
import { db, generateFirestorePath } from "src/helpers";
import { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { getGames } from "src/features/games/gamesSlice";
import { axiosInstance } from "src/helpers";
import { collection, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import { orgConverter } from "src/converters";
import { emailSchema } from "src/validation";
import TriumphPage from "src/shared/layout/TriumphPage";

const MemberSettings: FC = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { user } = useAppSelector((state) => state.userState);
  const { orgs } = useAppSelector((state) => state.orgState);
  const [org, setOrg] = useState<Organization | null>(null);
  const [members, setMembers] = useState<AdminUser[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [role, setRole] = useState("member");
  const [updatingRole, setUpdatingRole] = useState(false);
  const [preparingInvite, setPreparingInvite] = useState(false);
  const [isChangingRole, setIsChangingRole] = useState(false);
  const [selectedMember, setSelectedMember] = useState<MemberRow | null>(null);
  const [isRemovingMember, setIsRemovingMember] = useState(false);
  const [removingMember, setRemovingMember] = useState(false);
  const [isInvitingMember, setIsInvitingMember] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);

  interface MemberRow {
    title: string | null;
    avatar: string | null;
    email: string | null;
    lastLogin: number;
    role: string;
    uid: string;
  }

  async function sendInvite() {
    try {
      /** email to send Organization Invite to */
      const email: string = form.getFieldValue("invitationEmail");
      const {
        data: { success },
      } = await axiosInstance.post<{ success: boolean }>(
        `/admin/orgs/${user?.activeOrgId}/invite_member`,
        { email, role }
      );

      if (success) {
        message.success("Invite Sent!");
      } else {
        message.error("Invite failed to send.");
      }
    } catch (error) {
      console.error(error);
      message.error("Unable to send invite.");
    }
  }

  async function updateRole() {
    try {
      const {
        data: { success },
      } = await axiosInstance.post<{ success: boolean }>(
        `/admin/orgs/${user?.activeOrgId}/update_role`,
        { role, targetMemberUid: selectedMember?.uid }
      );

      if (success) {
        message.success(`Updated role to ${role}`);
      } else {
        message.error("Role update failed.");
      }
    } catch (error) {
      console.error(error);
      message.error("Unable to update");
    }
  }

  async function removeMember() {
    try {
      const {
        data: { success },
      } = await axiosInstance.post<{ success: boolean }>(
        `/admin/orgs/${user?.activeOrgId}/remove_member`,
        { targetMemberUid: selectedMember?.uid }
      );

      if (success) {
        message.success(`Removed ${selectedMember?.email}`);
      } else {
        message.error("Failed to remove member.");
      }
    } catch (error) {
      console.error(error);
      message.error("Unable to remove member.");
    }
  }

  function checkIsValidEmail() {
    const email: string = form.getFieldValue("invitationEmail");
    const { success: isValidEmail } = emailSchema.safeParse(email);
    const alreadyExists = members.some((member) => member.email === email);

    return !alreadyExists && isValidEmail;
  }

  useEffect(() => {
    if (!user || !user.activeOrgId) {
      return;
    }
    const activeOrg = orgs[user.activeOrgId];
    if (!activeOrg) {
      return;
    }

    async function fetchUsers() {
      try {
        const {
          data: { members },
        } = await axiosInstance.get<{ members: AdminUser[] }>(
          `/admin/orgs/${user?.activeOrgId}/members`
        );
        setMembers(members);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }

    let unsubOrganizationSnapshot: Unsubscribe;
    if (user && user.activeOrgId) {
      const organizationRef = collection(
        db,
        generateFirestorePath("organizations")
      ).withConverter(orgConverter);

      const orgDoc = doc(organizationRef, user.activeOrgId);
      unsubOrganizationSnapshot = onSnapshot(orgDoc, (orgsSnap) => {
        fetchUsers();
        const data = orgsSnap.data();
        if (!data) {
          return;
        }
        setOrg(data);
      });
    }
    return () => {
      unsubOrganizationSnapshot();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgs]);

  // fetch other org info (games / stats) for this user
  useEffect(() => {
    async function fetchOrgData() {
      if (orgs) {
        await Promise.all([dispatch(getGames())]);
      }
    }
    fetchOrgData();
  }, [dispatch, orgs]);

  const { Text, Title } = Typography;

  const handleMenuClick: MenuProps["onClick"] = (e) => {
    if (e.key === "changeRole") {
      setUpdatingRole(true);
    } else if (e.key === "removeMember") {
      setRemovingMember(true);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item icon={<KeyOutlined />} key={"changeRole"}>
        Change role
      </Menu.Item>
      <Menu.Item icon={<UserDeleteOutlined />} key={"removeMember"}>
        Remove from organization
      </Menu.Item>
    </Menu>
  );

  const memberCardTitle = (
    <>
      <Row style={{ width: "100%" }} justify="space-between" align="middle">
        <Text>Members</Text>
        <Button onClick={() => setPreparingInvite(true)} shape="round">
          Invite Member
        </Button>
      </Row>
    </>
  );

  function onRoleSelect(e: RadioChangeEvent) {
    setRole(e.target.value);
  }

  const roleSelect = (
    <Radio.Group
      style={{ marginTop: "10px" }}
      onChange={onRoleSelect}
      value={role}
    >
      <Space direction="vertical">
        <Card title={null}>
          <Radio value={"owner"}>
            <Col>
              <Title
                className={"text-disable"}
                style={{ margin: "0px" }}
                level={5}
              >
                Owner
              </Title>
              <Text className={"text-disable"} type="secondary">
                New owners will have administrative control of the organization.
                Initiating transfers, editing permissions, and removing users
                are enabled.
              </Text>
            </Col>
          </Radio>
        </Card>
        <Card>
          <Radio value={"member"}>
            <Col>
              <Title
                className={"text-disable"}
                style={{ margin: "0px" }}
                level={5}
              >
                Member
              </Title>
              <Text className={"text-disable"} type="secondary">
                Can view organization details such as other existing members,
                games, analytics, and gameplay history.
              </Text>
            </Col>
          </Radio>
        </Card>
      </Space>
    </Radio.Group>
  );

  const deleteContent = (
    <>
      <Text type="secondary" strong={true}>
        This will delete the following members:
      </Text>
      <Card style={{ marginTop: "10px" }}>
        <Row justify="start">
          <Space>
            <Avatar
              src={
                <Image
                  preview={false}
                  draggable={false}
                  referrerPolicy="no-referrer"
                  src={selectedMember?.avatar ?? ""}
                  fallback={"fallback_img.png"}
                />
              }
            />
            <Text type="danger" strong={true}>
              {selectedMember?.email}
            </Text>
          </Space>
        </Row>
      </Card>
    </>
  );

  const memberInvite = (
    <>
      <Form
        className="pt-2"
        name="app-config"
        form={form}
        colon={false}
        autoComplete="off"
        requiredMark={"optional"}
      >
        <Row className="pb-2 pt-2" justify="center">
          <Col span={24}>
            <Form.Item
              label={<span />}
              name="invitationEmail"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Invite email is not valid.",
                },
                {
                  message: "Member already exists!",
                  validator: (_, value) => {
                    if (members.some((member) => member.email === value)) {
                      return Promise.reject();
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <FloatInput
                name="invitationEmail"
                size="middle"
                placeholder=""
                label="Invitation Email"
                onChange={() => setIsValidEmail(checkIsValidEmail())}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Text type="secondary">Select new member access role</Text>
      {roleSelect}
    </>
  );

  const data = members.map((member) => {
    const role = org?.roles[member.uid] ?? "Unknown Role";
    const roleFormatted = role.charAt(0).toUpperCase() + role.slice(1);
    const info: MemberRow = {
      title: member.displayName,
      avatar: member.photoURL,
      email: member.email,
      lastLogin: member.lastLoginAt,
      role: roleFormatted,
      uid: member.uid,
    };
    return info;
  });

  const membersList = (
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item
          key={item.email}
          onClick={() => {
            setSelectedMember(item);
          }}
        >
          <List.Item.Meta
            avatar={
              <Avatar
                src={
                  <Image
                    preview={false}
                    draggable={false}
                    referrerPolicy="no-referrer"
                    src={item.avatar ?? ""}
                    fallback={"fallback_img.png"}
                  />
                }
              />
            }
            title={item.title}
            description={item.email}
          />
          <Row style={{ width: "35%" }} align="middle" justify="end">
            <Space size={60}>
              <div
                style={{
                  alignContent: "center",
                  width: "100px",
                  textAlign: "center",
                }}
              >
                <Text>{item.role}</Text>
              </div>
              <div style={{ marginLeft: "50px", width: "170px" }}>
                <Text italic type="secondary">
                  Last login on {new Date(item.lastLogin).toLocaleString()}
                </Text>
              </div>
              {user && org?.roles[user.uid] === "owner" && (
                <Dropdown overlay={menu}>
                  <Button shape="round" type="default">
                    <SettingOutlined style={{ fontSize: "16px" }} />
                    <DownOutlined style={{ fontSize: "10px" }} />
                  </Button>
                </Dropdown>
              )}
            </Space>
          </Row>
        </List.Item>
      )}
    />
  );

  return (
    <TriumphPage>
      <Modal
        title="Invite a Member"
        centered
        closable={false}
        open={preparingInvite}
        onCancel={() => {
          setPreparingInvite(false);
          form.resetFields();
        }}
        width={500}
        footer={[
          <Button
            type="primary"
            key="submit"
            disabled={!isValidEmail}
            loading={isInvitingMember}
            onClick={async () => {
              setIsInvitingMember(true);
              await sendInvite();
              setIsInvitingMember(false);
              setPreparingInvite(false);
              form.resetFields();
            }}
          >
            Send Invite
          </Button>,
        ]}
      >
        <>{memberInvite}</>
      </Modal>
      <Modal
        title={
          <Text className={"text-disable"} type="secondary">
            {" "}
            Update role of {selectedMember?.email}{" "}
          </Text>
        }
        centered
        closable={false}
        open={updatingRole}
        onCancel={() => {
          setUpdatingRole(false);
        }}
        width={500}
        footer={[
          <Button
            type="primary"
            key="submit"
            loading={isChangingRole}
            onClick={async () => {
              setIsChangingRole(true);
              await updateRole();
              setIsChangingRole(false);
              setUpdatingRole(false);
            }}
          >
            Change role
          </Button>,
        ]}
      >
        <>{roleSelect}</>
      </Modal>
      <Modal
        title={<Text type="secondary">Removing member from {org?.name}</Text>}
        centered
        closable={false}
        open={removingMember}
        onCancel={() => {
          setRemovingMember(false);
        }}
        width={500}
        footer={[
          <Button
            type="primary"
            danger={true}
            key="submit"
            loading={isRemovingMember}
            onClick={async () => {
              setIsRemovingMember(true);
              await removeMember();
              setIsRemovingMember(false);
              setRemovingMember(false);
            }}
          >
            Remove member
          </Button>,
        ]}
      >
        <>{deleteContent}</>
      </Modal>
      <Row justify="center">
        <Col span={18}>
          <Card
            title={memberCardTitle}
            headStyle={{ borderWidth: 0, fontSize: 28 }}
            bordered={false}
            style={{
              width: "100%",
            }}
          >
            {loading ? <Spin /> : membersList}
          </Card>
        </Col>
      </Row>
    </TriumphPage>
  );
};

export default MemberSettings;
