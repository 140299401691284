import {
  createSlice,
  createAsyncThunk,
  SerializedError,
  createAction,
} from "@reduxjs/toolkit";
import dayjs from "dayjs";
import { auth } from "src/helpers";
import { getDoc, doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { colNames, getCollection } from "src/collections";
/** type imports */
import type { RootState } from "src/app/store";

interface AdminAuthState {
  user: AdminUser | null;
  loading: "idle" | "pending";
  isfetchingUser: boolean;
  currentRequestId: string | undefined;
  error: null | SerializedError;
}

const initialState: AdminAuthState = {
  user: null,
  loading: "idle",
  currentRequestId: undefined,
  error: null,
  isfetchingUser: true,
};

function unixProcessor(strMilliSec: string): {
  seconds: number;
  nanoseconds: number;
} {
  const milliSec = parseInt(strMilliSec);
  const seconds = Math.floor(milliSec / 1000);
  const nanoseconds = (milliSec - seconds * 1000) * 1000000;
  return { seconds, nanoseconds } as const;
}

export const clearUser = createAction("user/clearUser");
export const fetchUpsertUser = createAsyncThunk<
  AdminUser | null,
  FirebaseAuthUser | null,
  { state: RootState }
>("user/fetchUpsertUser", async (payload, { getState, requestId }) => {
  const { loading, currentRequestId } = getState().userState;
  if (loading !== "pending" || requestId !== currentRequestId) {
    return null;
  }
  if (payload === null) {
    return null;
  }
  const { uid } = payload;
  const adminUserColRef = getCollection(colNames.adminUsers);
  const userRef = doc(adminUserColRef, uid);
  let userSnapshot;
  let user: AdminUser | undefined;
  try {
    userSnapshot = await getDoc(userRef);
    user = userSnapshot.data();
  } catch (error) {
    if (
      !(error instanceof Error) ||
      error.message !== "Missing or insufficient permissions."
    ) {
      throw error;
    }
  }
  let createdAt = dayjs().valueOf();
  if (payload.createdAt) {
    const { seconds, nanoseconds } = unixProcessor(payload.createdAt);
    createdAt = new Timestamp(seconds, nanoseconds).toMillis();
  }
  const { email, displayName, phoneNumber, photoURL, emailVerified } = payload;
  if (!user) {
    /**
     * create new user document
     */
    const newUser: AdminUser = {
      uid,
      email: email ?? null,
      displayName: displayName ?? null,
      emailVerified: emailVerified ?? false,
      phoneNumber: phoneNumber ?? null,
      photoURL: photoURL ?? null,
      isAnonymous: payload.isAnonymous ?? true,
      newlyCreated: true,
      lastLoginAt: createdAt,
      activeOrgId: null,
      orgIds: [],
      games: {},
      createdAt,
      updatedAt: createdAt,
    };
    try {
      await setDoc(userRef, newUser);
    } catch (error) {
      console.error("set doc", error);
      throw error;
    }
    user = newUser;
  } else {
    const userUpdate: Partial<AdminUser> = {};
    let hasUpdated = false;
    if (!("isAnonymous" in user)) {
      userUpdate.isAnonymous = payload.isAnonymous;
      hasUpdated = true;
    }
    if (!("createdAt" in user)) {
      userUpdate.createdAt = createdAt;
      hasUpdated = true;
    }
    if (!("email" in user) && payload.email && payload.email !== "") {
      userUpdate.email = payload.email;
      hasUpdated = true;
    }
    if (hasUpdated) {
      userUpdate.updatedAt = dayjs().valueOf();
    }
    try {
      await updateDoc(userRef, userUpdate);
    } catch (error) {
      console.error("update doc", error);
      throw error;
    }
  }

  userSnapshot = await getDoc(userRef);
  user = userSnapshot.data();
  if (!user) {
    return null;
  }
  return user;
});

export const fetchUser = createAsyncThunk<
  AdminUser | null,
  void,
  { state: RootState }
>("user/fetchUser", async (payload, { getState, requestId }) => {
  const uid = auth.currentUser?.uid;
  if (!uid) {
    throw new Error("user not authenticated");
  }
  const { loading, currentRequestId } = getState().userState;
  if (loading !== "pending" || requestId !== currentRequestId) {
    return null;
  }

  const adminUserColRef = getCollection(colNames.adminUsers);
  const userRef = doc(adminUserColRef, uid);
  let userSnapshot;
  let user: AdminUser | undefined;
  userSnapshot = await getDoc(userRef);
  user = userSnapshot.data();
  if (!user) {
    return null;
  }
  return user;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUpsertUser.pending, (state, action) => {
      const {
        meta: { requestId },
      } = action;

      if (state.loading === "idle") {
        state.loading = "pending";
        state.isfetchingUser = true;
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(fetchUpsertUser.fulfilled, (state, action) => {
      const {
        meta: { requestId },
        payload,
      } = action;

      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.user = payload;
        state.currentRequestId = undefined;
        state.loading = "idle";
        state.isfetchingUser = false;
        state.error = null;
      }
    });
    builder.addCase(fetchUpsertUser.rejected, (state, action) => {
      const {
        meta: { requestId },
        error,
      } = action;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.isfetchingUser = false;
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      const {
        meta: { requestId },
        payload,
      } = action;

      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.user = payload;
        state.currentRequestId = undefined;
        state.loading = "idle";
        state.isfetchingUser = false;
        state.error = null;
      }
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      const {
        meta: { requestId },
        error,
      } = action;
      if (state.loading === "pending" && state.currentRequestId === requestId) {
        state.loading = "idle";
        state.isfetchingUser = false;
        state.error = error;
        state.currentRequestId = undefined;
      }
    });
    builder.addCase(fetchUser.pending, (state, action) => {
      const {
        meta: { requestId },
      } = action;

      if (state.loading === "idle") {
        state.loading = "pending";
        state.isfetchingUser = true;
        state.currentRequestId = requestId;
      }
    });
    builder.addCase(clearUser, (state, action) => {
      state.user = null;
    });
  },
});

export default userSlice.reducer;
export const selectUser = (state: RootState) => state.userState;
